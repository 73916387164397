
import React, { useState, useEffect } from "react";
import { Link as ReactRouterLink } from 'react-router-dom'
import cls from 'classnames';
import _ from "lodash";
import Track from 'utils/track'

// Create a wrapper so we can add tracking onto it
const Link = ({ className, children, onClick=()=>{}, marker, external, ...props }) => {
    
    const handleClick = e => {
        Track.click(e, marker)
        onClick(e)
    }

    const attrs = {
        ...props,
        className,
        onClick: handleClick,
    }

    // react-router cannot detect external URL, omg, so have to 
    // handle it manually by using <a/> tag...
    //  - https://github.com/ReactTraining/react-router/issues/1147
    if (external){
        return <a href={attrs.to} {...attrs}>{children}</a>
    }

    return (
        <ReactRouterLink {...attrs}>{children}</ReactRouterLink>
    )
}


export default Link