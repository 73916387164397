import React, { useState, useEffect } from "react";
import { useMatch, useNavigate, matchPath, useLocation } from 'react-router-dom'
import { observer } from "mobx-react";
import cls from 'classnames';
import _ from "lodash";
import { viewport, meStore, hireStore } from 'stores'
import J from 'utils/standard';
import Track from 'utils/track'
import { useScrollToTop } from 'utils/hooks'
import FullScreenLoader from 'ui/full-screen-loader'
import PhoneInput from 'ui/phone-input'
import Select from 'ui/select'
import Button from 'ui/button'
import Link from 'ui/link'
import PlainArticle from 'components/plain-article'
import "./style.css";


const FAQ = () => {
    useScrollToTop()
    const { our_contact_email } = meStore.constants
    const { is_mobile } = viewport
    const email = <a href={`mailto:${our_contact_email}`}>{our_contact_email}</a>

    const props = {
        title: is_mobile ? 'FAQs' : 'Frequently Asked Questions',
        subtitle: `List of Our Commonly Asked Questions`,
    }
    
    return (
        <PlainArticle {...props} className='faq'>
            <h2>Our Hiring Process</h2>
            <h4>What is Peerku?</h4>
            <p>
                Peerku focuses on hiring world-class remote engineers and teams for 
                your company. Engineers are pre-interviewed by industry veterans from 
                Google and Facebook to ensure they have great technical expertise  
                and collaborate well in a team environment. In other words, we provide 
                the talents you need, so you can focus on shipping great product. 
                In a typical setup, we'll introduce you to candidates within 24 hours, 
                and get your engineers fully onboarded within 1-3 days.
            </p>
            <h4>How can I hire engineers?</h4>
            <ol>
                <li><p>
                    <b>Tell Us Your Needs: </b> 
                    So we can know about your company and the engineers you're looking 
                    to hire. <Link to='/hire'>Get started here</Link>.
                </p></li>
                <li><p>
                    <b>You Review the engineers: </b> 
                    Review the engineers we selected for you, and decide if you wish to start.
                </p></li>
                <li><p>
                    <b>Start Working: </b>
                    Once you decide to start, we'll help take care of all the paperwork and 
                    payment processing. If you wish to hire more than one engineer, our hiring 
                    consultant can help you fill multiple roles or build entire remote teams.
                </p></li>
            </ol>
            <h4>What type of workers are the engineers?</h4>
            <p>
                All engineers are considered contractors. You can hire engineers for any 
                duration, full-time or part-time. Peerku will help take care of payrolls
                and other logistics.
            </p>
            <h4>Are English skills ever an issue when working withan engineer?</h4>
            <p>
                No. Each and every Peerku engineer we vet, writes and speaks fluent English. 
                In fact, during our interview, we ensure the candidates have a high level of 
                proficiency in English and have personalities well-suited to working with 
                western technical teams.
            </p>
            <h4>Where are the engineers located?</h4>
            <p>
                Currently, we have engineers in over 100 countries, with most of them located  
                in Americas, Europe and Asia. The vast majority work remotely from their 
                home office or a co-working space.
            </p>

            <hr/>

            <h2>Quality of Talents</h2>
            <h4>What's your engineer vetting process?</h4>
            <p>
                Each candidate goes through a 4-hour, multi-round interview 
                conducted by veteran engineers from Google and Facebook 
                to ensure they have solid technical skills and are all-around 
                good team players.
            </p>
            <ol>
                <li><p>
                    <b>Deep dive round: </b>
                    For each resume point, ask detailed questions on implementation details, 
                    design decisions, along with multiple follow-ups. Through this, we fully 
                    examine their technical knowledge's breadth and depth.
                </p></li>
                <li><p>
                    <b>Live coding round: </b>
                    Assess domain-specific skills and code quality and - we want candidates 
                    who can ship, not just talk.
                </p></li>
                <li><p>
                    <b>Behavior round: </b>
                    ensure the candidate has good communication skills and collaborates well in 
                    a team environment. The candidate is also tested on their English proficiency, 
                    making sure they have no problem during your collaboration.
                </p></li>
                <li><p>
                    <b>Background check: </b>
                    After the candidate passed all of the above, a background check is conducted 
                    to gain additional feedback from his/her past employers.
                </p></li>
            </ol>
            <h4>What if I'm not satisfied with an engineer?</h4>
            <p>
                While we rigorously interview all applicants to ensure skills and personality, 
                we understand that not every person will be a perfect fit for every company. 
                If you'd like to request different engineers or to stop completely, you can 
                do so at any time. Simply let our hiring consultant know, and we'll work 
                with you to help you out.
            </p>
        
            <hr/>

            <h2>Intellectual Property</h2>
            <h4>Will my intellectual property be protected?</h4>
            <p>
                Absolutely! As a client, you will own 100% the intellectual property of all 
                paid-for works. We also provide an NDA to protect the confidentiality 
                of your request, and all Peerku engineers are also covered under the 
                agreement. Nevertheless, if you'd like the engineers to enter 
                into a direct agreement with you to effect the direct transfer of IP 
                property, we can work with you to ensure that your needs are met.
            </p>

            <hr/>

            <h2>Pricing & Payment</h2>
            <h4>How much does it cost to hire an engineer?</h4>
            <p>
                Since we have engineers all over the world, pricing can vary across 
                several factors. We will work with you to find the right price point 
                for your project. Typically, engineers charge between $60-$100+/hour (USD). 
                If you have other requirements, you can always discuss details with our 
                hiring consultant to ensure vetted candidates meet your needs. 
                Click <Link to='/hire'>here</Link> to get started for a consultation.
            </p>
            <h4>How is payment handled?</h4>
            <p>
                We typically invoice our clients once a week, though there is some 
                flexibility here. We currently accept all major debit cards, credit 
                cards, ACH, bank wires, and PayPal.
            </p>

            <hr/>

            <h2>Other Questions</h2>
            <h4>Can I bring the engineers to our physical office?</h4>
            <p>
                In practice, there have only been a few instances where clients have felt it 
                absolutely necessary to bring a Peerku engineer physically on-site. If this 
                truly is a requirement, we can work with you to coordinate relocating an 
                individual or team to your location for a specified period of time.
            </p>
            <h4>Do you offer other services such as screening, vetting, and testing to help us  
                hire employees outside of the Peerku network?
            </h4>
            <p>
                Yes! Please send us an email at {email}, we'll follow-up with you.
            </p>
        </PlainArticle>
    )
}




export default observer(FAQ);