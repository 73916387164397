import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import cls from 'classnames';
import _ from "lodash";
import ReactPhone from 'react-phone-input-2'
import "./style.css";


const PhoneInput = ({ ...props }) => {
    return (
        <ReactPhone 
            // add extra class "force" to increase specifity, if it's not added, the 
            // default class still prevails because the selector was more specific
            containerClass='ui-phone-input force' 
            {...props} 
        />
    )
}

export default PhoneInput
