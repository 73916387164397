import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import cls from 'classnames';
import _ from "lodash";
import ReactSelect from 'react-select'
import VirtualizedSelect from 'react-virtualized-select'
import "./style.css";


// we do search on label (actual displayed text), and not value, 
// since value is more like an id, i.e. it could be dash separated 
// in the future. But for now both are the same.
const getLabel = item => item ? item.label : item
const convertToOption = value => ({ value: value, label: value })


// Created a class instead of functional component to because the latter
// causes a ref error, see https://github.com/JedWatson/react-select/issues/2181
class CustomCreatable extends React.Component {
    ref = React.createRef()
    render() {
        return (
            <ReactSelect
                ref={this.ref}
                {...this.props} 
                scrollMenuIntoView={false}
                onOpen={e => {
                    const { menuContainer } = this.ref.current || {}
                    if (menuContainer){
                        menuContainer.scrollIntoView()
                        // line below makes it works for mobile
                        setTimeout(() => menuContainer.scrollIntoView(), 160)
                    }
                }}
            />
        )
    }
}

const Select = ({ 
    creatable = false, 
    dropdownTakeSpace, 
    rawValues = true,
    options,
    onChange,
    searchable,
    optionHeight = 38,
    placeholder = 'Please select one',
    ...props 
}) => {
    const ref = React.createRef()

    const additionalProps = {
        options,
        onChange,
        searchable,
    }
    
    if (creatable){
        Object.assign(additionalProps, {
            selectComponent: CustomCreatable
        })
    }

    // default "options" parameter of this library expects an array of {label, value}, 
    // which is a hassle, because if we have a flat array of strings, we have to 
    // convert it to an array of jsons
    //  - so we change the API
    //  - when "rawValues=true", we can provide just an array of strings
    if (rawValues){
        Object.assign(additionalProps, {
            options: options.map(convertToOption),
            onChange: (obj, ...params) => {
                return onChange(
                    _.isArray(obj) 
                        ? obj.map(getLabel)
                        : getLabel(obj), 
                    ...params
                )
            }
        })
    }

    // replace default search method
    //  - to give priority to 
    //      1. strings that match the beginning
    //      2. strings that match the FIRST letter of each word
    //  - the default order of this library gives no priority 
    //    to ones that match the beginning, so if a string is 
    //    matched in the middle, it can still be shown first
    //    which is not good.
    if (searchable){
        Object.assign(additionalProps, {
            filterOptions: (all_options, keyword, selected_options) => {
                const first = [] // shown first
                const rest = [] 
                const exist = new Set((selected_options || []).map(getLabel))
                for (const option of all_options){
                    const label = getLabel(option)
                    const str = label.toLowerCase() // ignore case
                    const first_letters = str.split(' ').map(s => s[0]).join('') // united states => us
                    if (exist.has(label)) continue
                    const pos = str.indexOf(keyword)
                    // give priority to 
                    //   1. strings that match the beginning
                    //   2. strings that match the FIRST letter of each word
                    if (pos === 0 || first_letters.indexOf(keyword) === 0) {
                        first.push(option)
                    }
                    else if (pos >= 1) rest.push(option)
                }
                return first.concat(rest)
            }
        })
    }
    
    
    return (
        <VirtualizedSelect 
            ref={ref}
            placeholder={placeholder}
            className={cls('ui-select', {'dropdown-take-space': dropdownTakeSpace })}
            optionHeight={optionHeight}
            {...props} 
            {...additionalProps}
        />
    )
}




export default Select
export { getLabel, convertToOption }