import React, { useState, useEffect } from "react";
import { useLocation, matchPath } from 'react-router-dom'
import { observer } from 'mobx-react'
import cls from 'classnames'
import _ from "lodash";
import { useClickOutside } from 'utils/hooks'
import { viewport } from 'stores'
import Logo from 'components/logo'
import Button from 'ui/button'
import Link from 'ui/link'
import "./style.css";


const Topbar = observer(() => {
    const location = useLocation()
    const [modal_visible, setModalVisible] = useState(false)
    const { is_desktop } = viewport

    const [modal_ref] = useClickOutside({
        except: ['.topbar .hamburger-menu', '.topbar .pop'],
        run: () => setModalVisible(false)
    })

    const tabs = [
        { url: '/how-it-works', text: 'How it works' },
        // { url: '/resources',  	text: 'Resources' },
        { url: '/about', 	   	text: 'About us' },
        { url: '/faq', 	   	text: 'Read FAQs' },
    ]
    
    const EngButton = props => (
        <div {...props}>
            <Button to='/apply' type='default'>FOR ENGINEERS</Button>
        </div>
    )
    const HireButton = props => (
        <div {...props}>
            <Button to='/hire'>HIRE A DEVELOPER</Button>
        </div>
    )

    return (
        <header 
            className='topbar' 
            data-ham={!is_desktop} 
            data-show-ham={modal_visible}
            data-marker='topbar'
        >
            <div className='left'>
                <Logo />
                {tabs.map(tab => (
                    <Link key={tab.url} to={tab.url} className='tab btn' 
                        data-active={!!matchPath(location.pathname, tab.url)}
                    >
                        {tab.text}
                    </Link>
                ))}
            </div>
            <div className='right'>
                <div className='hamburger-menu center-contained unselectable' onClick={() => setModalVisible(!modal_visible)}></div>
                <div ref={modal_ref} className='pop'>
                    {tabs.map(tab => (
                        <Link key={tab.url} to={tab.url} className='item text unselectable' data-active={matchPath(location.pathname, tab.url)} onClick={() => setModalVisible(false)}>
                            {tab.text}
                        </Link>
                    ))}
                    <div className='item'>
                        <EngButton className='eng' />
                        <HireButton />
                    </div>
                    
                </div>
                <EngButton />
                <HireButton className='hire' />
            </div>

        </header>
    )
})




export default Topbar;