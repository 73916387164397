
import React, { useState, useEffect } from "react";
import { useMatch, useNavigate, matchPath, useLocation } from 'react-router-dom'
import cls from 'classnames';
import _ from "lodash";
import "./style.css";

const Highlight = ({ 
    // css override
    margin = 10, 
    height = '40%', 
    bottom = -4, 
    // color
    green, yellow, blue,
    // text css override
    textStyle = {},
    // children
    children, 
}) => {

    const color = { green, yellow, blue }

    const style = {
        left: -1 * margin,
        width: `calc(100% + ${margin*2}px)`,
        height,
        bottom,
    }

    return (
        <span className={cls('ui-highlight', color)} style={textStyle}>
            {children}
            <span className='bar' style={style}></span>
        </span>
    )
}


export default Highlight