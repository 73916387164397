import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import cls from 'classnames';
import _ from "lodash";
import J from 'utils/standard';
import { viewport } from 'stores'
import Button from 'ui/button'
import Link from 'ui/link'
import "./style.css";



const Company = observer(() => {
    const { is_mobile } = viewport

    return (
        <div className='company'>

            <div className='banner center-cropped'>
                <div className='block left'>
                    <div className='title'>
                        Hire great remote engineers. Reliable & Affordable.
                    </div>
                    <div className='desc'>
                        Peerku is the world’s first tech-enabled platform for hiring remote engineering teams. Built by ex-Google, ex-Facebook engineers, we help you effortlessly find senior developers around the globe, fast.
                    </div>
                    <div className='action'>
                        <Button to='/how-it-works' marker='homepage-banner'>
                            GET STARTED
                        </Button>
                    </div>
                </div>
                <div className='block right'>
                    <div className='img center-contained'></div>
                </div>
            </div>

            <div className='section big-quote'>
                <div className='wrap'>
                    <div className='bracket-icon first'>{'{'}</div>
                    <div className='text-area'>
                        <h2>Bringing the best, outside the US.</h2>
                        <p>We believe remote work is the future. With Peerku, you're no longer restricted to hiring within your geographical location. You gain access to talent, across all markets. Within them, we select only the best for you.</p>
                    </div>
                    <div className='bracket-icon second'>{'}'}</div>
                </div>
            </div>

            <div className='section pre-checked'>
                <div className='wrap'>
                    <div className='text-area'>
                        <h2>
                            Pre-checked by Google
                            <span className='gray'>+</span>
                            FB Engineers. 💖
                        </h2>
                        <p>
                            All candidates go through a rigorous interview 
                            conducted by industry veterans from the Big Four (Facebook, 
                            Google, Apple, Amazon). We thoroughly examine a 
                            candidate's both technical breadth and depth.
                        </p>
                    </div>
                </div>
                <div className='icons'>
                    <div className='fb img center-cropped'></div>
                    <div className='gg img center-cropped'></div>
                    <div className='ap img center-cropped'></div>
                    <div className='am img center-cropped'></div>
                    {/* <div className='ms img center-cropped'></div> */}
                </div>
            </div>

            <div className='section tech-stacks'>
                <div className='wrap'>
                    <div className='text-area'>
                        <h2>Various tech stacks. 🛠️</h2>
                        <p>
                            Hire engineers who have expertise in React, Vue, Angular,
                            Android, Java, Objective-C, Swift, Flutter, React Native, 
                            Python, Golang, Node, Rails, PHP, DevOps, Machine Learning, 
                            Data Engineering, and more.
                        </p>
                    </div>
                    <div className='tags'>
                        {/* icon color is #71757B */}
                        {[['Full-Stack Developer', '/img/dev-fullstack.svg'],
                          ['Front-End Developer', '/img/dev-frontend.svg'],
                          ['Back-End Developer', '/img/dev-backend.svg'],
                          ['iOS Developer', '/img/dev-ios.svg'],
                          ['Android Developer', '/img/dev-android.svg'],
                          ['Machine Learning Engineer', '/img/dev-ml.svg'],
                          ['Data Scientist', '/img/dev-data.svg'],
                          ['DevOps Engineer', '/img/dev-devops.svg'],
                          ['Low-Level System Engineer', '/img/dev-system.svg'],
                         ].map(([role, icon]) => (
                            <div key={role} className='tag'>
                                <div className='icon' style={J.bgUrl(icon)}></div>
                                <div className='txt'>{role}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className='section overview'>
                <h2>How it works</h2>
                <div className='wrap'>
                    <div className='step'>
                        <div className='title'>
                            <div className='num'>1</div>
                            <h3>We Interview Developers</h3>
                        </div>
                        <div className='desc'>
                            <p className='txt'>Developers go through extensive, multi-round interviews with senior engineers at Google, Facebook, Apple, Amazon to ensure they have solid technical skills and are all-around good team players.</p>
                            <div className='skills'>
                                <div className='inner'>
                                    {[['React', '/img/skill-react.svg'],
                                    ['Vue', '/img/skill-vue.svg'],
                                    ['Angular', '/img/skill-angular.svg'],
                                    //['Typescript', '/img/skill-ts.svg', true],
                                    ['Docker', '/img/skill-docker.svg'],
                                    ['Android', '/img/skill-android.svg'],
                                    ['Swift', '/img/skill-swift.svg'],
                                    ['Python', '/img/skill-python.svg'],
                                    ['Java', '/img/skill-java.svg'],
                                    ['Spring', '/img/skill-spring.svg', true],
                                    ['PHP', '/img/skill-php.svg'],
                                    ['Go', '/img/skill-go.svg'],
                                    //['Ruby', '/img/skill-ruby.svg', true],
                                    //['Asp.net', '/img/skill-aspnet.svg', true],
                                    //['C#', '/img/skill-csharp.svg', true],
                                    //['C++', '/img/skill-cpp.svg', true],
                                    //['Scala', '/img/skill-scala.svg', true],
                                    //['Tensorflow', '/img/skill-tensorflow.svg', true],
                                    ['AWS', '/img/skill-aws.svg'],
                                    ['And more', '/img/skill-more.svg'],
                                    ].map(([skill, icon, hide_on_mobile]) => (
                                        (hide_on_mobile && is_mobile) ? null : (
                                            <div key={skill} className='skill'>
                                                <div className='icon center-contained' style={J.bgUrl(icon)}></div>
                                                <div className='txt unselectable'>{skill}</div>
                                            </div>
                                        )
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='step'>
                        <div className='title'>
                            <div className='num'>2</div>
                            <h3>You Meet Top Candidates</h3>
                        </div>
                        <div className='desc'>
                            <p className='txt'>Let us know your specific requirements and we'll provide the best developers in a matter of days. You then interview them to assess if they are a good cultural and team fit.</p>
                            <div className='pic center-contained' style={J.bgUrl('/img/overview2a.svg')}></div>
                        </div>
                    </div>
                    <div className='step'>
                        <div className='title'>
                            <div className='num'>3</div>
                            <h3>We Handle the Rest</h3>
                        </div>
                        <div className='desc'>
                            <p className='txt'>Peerku manages payroll, benefits, and compliance for all remote hires. You simply choose who to hire, we'll handle the logistics.</p>
                            <div className='pic center-contained' style={{ height: 290, ...J.bgUrl('/img/overview3a.svg')}}></div>
                        </div>
                    </div>
                    <div className='quote'>
                        <h3 className='txt'>Hire Great Engineers, 4x Faster.</h3>
                        <Button to='/how-it-works' marker='homepage-bottom'>
                            GET STARTED
                        </Button>
                    </div>
                    
                </div>
                
            </div>

        </div>
    )
})




export default Company;