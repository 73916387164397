import _ from "lodash";
import J from 'utils/standard';
import { auto } from 'utils/mobx'


class MeStore {

    // app configs (set on page initialization)
    app_config = {};

    // shared constants between server and client
    constants = {};

    // user data
    user_id = null;
    name = '';
    avatar = '';

    // am i currently logged in
    get isLoggedIn() {
        return !!this.user_id;
    }

    // fetch my user data + init data + global headers (to carry in all requests)
    fetch = async () => {
        const { user_doc, app_config = {}, constants = {}, error } = await J.post('/init-data', {}, { ajaxBar: false });
        if (error) console.error(error);
        // app config
        this.app_config = app_config;
        this.constants = constants;
        // user data 
        if (user_doc){  
            this.user_id = user_doc.user_id;
            this.name = user_doc.name;
            this.avatar = user_doc.avatar;
        } else {
            // console.log('not logged in');
        }
    }

    // Logout
    logout = async () => {
        await J.post('/logout');
        window.location.reload();
    } 
}

export default new auto(MeStore)()