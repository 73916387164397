import React, { useState, useEffect } from "react";
import { useMatch, useNavigate, matchPath, useLocation } from 'react-router-dom'
import { observer } from "mobx-react";
import cls from 'classnames';
import _ from "lodash";
import { viewport, meStore, hireStore } from 'stores'
import J from 'utils/standard';
import Track from 'utils/track'
import { useScrollToTop } from 'utils/hooks'
import FullScreenLoader from 'ui/full-screen-loader'
import PhoneInput from 'ui/phone-input'
import Select from 'ui/select'
import Button from 'ui/button'
import Link from 'ui/link'
import PlainArticle from 'components/plain-article'
import "./style.css";


const Terms = () => {
    useScrollToTop()
    const { our_contact_email } = meStore.constants
    const email = <a href={`mailto:${our_contact_email}`}>{our_contact_email}</a>

    const props = {
        title: 'Terms Of Use',
        subtitle: `Last Revision: 2019/04/18`,
    }
    
    return (
        <PlainArticle {...props}>
            <p>
                Welcome to Peerku. Please read on to learn the rules and 
                restrictions that govern your use of our website(s), and our 
                products, services, applications and other offerings provided 
                through the website(s) (the "Services"). 
            </p>
            <p>
                These Terms of Use (the "Terms") are a binding contract between 
                you and Peerku, Inc. ("Peerku," "we" and "us"). You must 
                agree to and accept all of the Terms, or you don't have the 
                right to use the Services. Your using the Services in any way 
                means that you agree to all of these Terms, and these Terms 
                will remain in effect while you use the Services. These Terms 
                include the provisions in this document, as well as those in 
                the <Link to='/privacy'>Privacy Policy</Link>.
            </p>
            <p>
                Additional definitions
                <br/>
                Clients: companies/people who request the help of developers on 
                the Sites
                <br/>
                Developers: people who provide services to Clients
            </p>

            <h3>Updates To These Terms</h3>
            <p>
                We are constantly trying to improve our Services, so these Terms 
                may need to change along with the Services. We reserve the right 
                to change the Terms at any time. Your continued use of the website 
                after any such changes constitutes your acceptance of the new Terms 
                of Use.
            </p>
            <p>
                If you don't agree with the new Terms, you are free to reject them; 
                unfortunately, that means you will no longer be able to use the Services. 
                If you use the Services in any way after a change to the Terms is 
                effective, that means you agree to all of the changes.
            </p>
            <p>
                Except for changes by us as described here, no other amendment or 
                modification of these Terms will be effective unless in writing and 
                signed by both you and us.
            </p>

            <h3>Privacy of Users</h3>
            <p>
                Peerku takes the privacy of its users very seriously. For the current Peerku 
                Privacy Policy, please <Link to='/privacy'>click here</Link>.
            </p>
            <p>
                The Children's Online Privacy Protection Act ("COPPA") requires that online 
                service providers obtain parental consent before they knowingly collect 
                personally identifiable information online from children who are under 13. 
                We do not knowingly collect or solicit personally identifiable information 
                from children under 13; if you are a child under 13, please do not attempt 
                to register for the Services or send any personal information about yourself 
                to us. If we learn we have collected personal information from a child 
                under 13, we will delete that information as quickly as possible. If you 
                believe that a child under 13 may have provided us personal information, 
                please contact us at {email}.
            </p>

            <h3>Basic Conditions of Use</h3>
            <h4>Authorization to Access</h4>
            <p>
                Subject to your compliance with these Terms of Use and the provisions hereof,  
                you may access or use the Site and Content solely for the purpose of your 
                evaluation of Peerku and Peerku’s products and services. You may only link to 
                the Site or Content, or any portion thereof, as expressly permitted by Peerku.
            </p>
            <h4>Ownership and Restrictions</h4>
            <p>
                All rights, title and interest in and to the Site and Content will remain with 
                and belong exclusively to Peerku. You will not (a) sublicense, resell, rent, 
                lease, transfer, assign, time share or otherwise commercially exploit or make 
                the Site and any Content available to any third party, (b) use the Site and 
                Content in any unlawful manner (including without limitation in violation of 
                any data, privacy or export control laws) or in any manner that interferes with 
                or disrupts the integrity or performance of the Site and Content or their related 
                components, or (c) modify, adapt or hack the Site and Content to, or try to, 
                gain unauthorized access to the restricted portions of the Site and Content 
                or related systems or networks (i.e., circumvent any encryption or other 
                security measures, gain access to any source code or any other underlying form 
                of technology or information, and gain access to any part of the Site and Content, 
                or any other products or services of Peerku that are not readily made available 
                to the general public).
            </p>
            <p>
                You are not permitted to copy, modify, frame, repost, publicly perform or 
                display, sell, reproduce, distribute, or create derivative works of the Site 
                and Content, except that you may download, display, and print one copy of the 
                publicly available materials (i.e., the Content that does not require an 
                Account name or password to access) on any single computer solely for your 
                personal, non-commercial use, provided that you do not modify the material 
                in any way and you keep intact all copyright, trademark, and other proprietary 
                notices. You agree not to access the Site or Content by any means other than 
                through the interface that is provided by Peerku to access the same. You may 
                not use any "page-scrape," "deep-link," "spider," or "robot or other automatic 
                program, device, algorithm or methodology, or any similar manual process, to 
                access, copy, acquire, or monitor any portion of the Site or any Content, or 
                in any way reproduce or circumvent the presentation or navigational structure 
                of the Site or any Content, to obtain or attempt to obtain any Content or 
                other information through any means not made generally available through the 
                Site by Peerku. Peerku reserves the right to take any lawful measures to 
                prevent any such activity. You may not forge headers or otherwise manipulate 
                identifiers in order to disguise the origin of any message or transmittal 
                you send to Peerku on or through the Site or any service offered on or through 
                the Site. You may not pretend that you are, or that you represent, someone else, 
                or impersonate any other individual or entity.
            </p>
            <h4>
                Responsibility for Your Data
            </h4>
            <p>
                You are solely responsible for all data, information and other content, that 
                you upload, post, or otherwise provide or store (hereafter "post(ing)") in 
                connection with or relating to the Site.
            </p>
            <h4>Reservation of Rights</h4>
            <p>
                Peerku and its licensors each own and retain their respective rights in and 
                to all logos, company names, marks, trademarks, copyrights, trade secrets, 
                know-how, patents and patent applications that are used or embodied in or 
                otherwise related to the Site and Content. Peerku grants no rights or licenses 
                (implied, by estoppel, or otherwise) whatsoever to you under these Terms of Use.
            </p>

            <h3>Intellectual Property</h3>
            <p>
                Upon Developer's receipt of full payment from Client, the Work 
                Product of the Developer will be the sole and exclusive property of the Client, 
                and Client will be deemed to be the author thereof. The developer automatically 
                irrevocably assigns to Client all right, title and interest worldwide in and 
                to such Intellectual Property Rights and waives any moral rights, rights of 
                paternity, integrity, disclosure and withdrawal or inalienable rights under 
                applicable law in and to the Work Product.
            </p>
            <p>
                Our Site may contain links to third-party sites that are not owned or 
                controlled by Peerku, Inc. Trademarks of third-party sites, such as 
                names and logos, may be used on our site to identify the sites. We do 
                not claim any ownership of those names, slogans, or logos. Peerku, Inc 
                has no control over, and assumes no responsibility for, the content, 
                privacy policies, or practices of any third party sites or services. 
                We strongly advise you to read the terms and conditions and privacy 
                policy of any third-party site that you visit.
            </p>

            <h3>Termination of Access</h3>
            <p>
                You’re free to do stop using the Services and to request deletion of your 
                User Submissions at any time, by contacting us at {email}.
            </p>
            <p>
                Peerku is also free to terminate (or suspend access to) your use of the 
                Services, for any reason in our discretion, including your breach of these 
                Terms. Peerku has the sole right to decide whether you are in violation 
                of any of the restrictions set forth in these Terms.
            </p>

            <h3>Limitation of Liability</h3>
            <p>
                By using our Services, you agree that Peerku, Inc will not be liable for 
                any indirect, incidental, special or consequential damages, loss of revenue, 
                loss of data or emotional distress resulting from your use of the Site or 
                services.
            </p>

            <h3>Miscellaneous</h3>
            <p>
                You will be responsible for paying, withholding, filing, and reporting all taxes, 
                duties, and other governmental assessments associated with your activity in 
                connection with the Services, in accordance to the governing laws of the country 
                you reside in.
            </p>

            <h3>More Questions</h3>
            <p>
                If you have any questions or concerns regarding our Terms of Use, 
                please send us a detailed message to {email}, and we will try to  
                resolve your concerns.
            </p>
        </PlainArticle>
    )
}




export default observer(Terms);