/**
 * Load app initial data.
 * 
 */
import { meStore } from 'stores';
import J from 'utils/standard'



const init = async cb => {

    // generate device ID
    if (!J.getDeviceId()){
        J.setDeviceId()
    }

    // fetch my data
    await meStore.fetch()
    
    // after react finishes render, create an element on page that marks this,
    //   - so using puppeteer we can wait for this DOM node to appear 
    //   - thus be onfident at this point we can extract the HTML
    cb(() => {
        const div = document.createElement('div')
        div.id = meStore.constants.marker_element_indicates_page_render_completes
        document.body.appendChild(div)
    })
};

export default init;