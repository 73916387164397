/*
 * An abstraction layer on top of react-router.
 * 
 * It exposes an API to navigate histories.
 * It's written using MobX observable to allow component to 
 * react to URL changes.
 *
 */

import _ from 'lodash';
import { createBrowserHistory } from 'history';
import J from 'utils/standard';
import { auto } from 'utils/mobx'


// use a copy of history API
const api = createBrowserHistory()


class History {
    constructor() {
        this.historyStack = 0; // history stack
        this.previousLocation = {}; // keep track of previous location object
        this.currentLocation = api.location; // keep track of current location object, make it observable
    
        // on URL change
        api.listen(({ action, location }) => {
            // enable to determine if user can go back in history
            if (action === 'PUSH') this.historyStack++;
            else if (action === 'POP') this.historyStack--;
            if (this.historyStack < 0) this.historyStack = 0;
            // keep track of previous URL
            //  - also, if a component's render() contains "currentLocation", that
            //    component to re-render based on URL changes
            this.previousLocation = this.currentLocation;
            this.currentLocation = location;
        });
    }


    // same as native goBack(), but user can provide an optional fallback url, in case
    // browser has no previous history
    goBack(fallbackUrl) {
        if (this.canGoBack()) {
            api.goBack();
        } else {
            if (fallbackUrl) {
                console.log('no previous path, going to fallbackUrl', fallbackUrl);
                api.replace(fallbackUrl);
            } else {
                console.log('no previous path, and no fallbackUrl provided, going back blindly...');
                api.goBack();
            }
        }
    }

    // --------------- custom methods ---------------

    // check whether user can go back in history
    canGoBack() {
        return this.historyStack >= 1;
    }

    // get current URL's query params
    // ex: { foo: 'bar' }
    getQuery(optionalField){
        const params = new URLSearchParams(api.location.search)
        if (optionalField) {
            return params.get('optionalField')
        }
        else {
            const json = {}
            for (let [key, val] of params) {
                json[key] = val
            }
            return json
        }
    }

    // add query
    // - can either replace current URL, or push as new URL
    // - ex: history.addQuery({ paintRed: 1 })
    addQuery(query, { push = false } = {}) {
        const location = Object.assign({}, api.location);
        Object.assign(location.query, query);
        if (push) api.push(location);
        else api.replace(location);
    }

    // set query 
    setQuery(query, { push = false } = {}) {
        const location = Object.assign({}, api.location);
        location.query = query;
        if (push) api.push(location);
        else api.replace(location);
    }

    // remove query 
    // - can either replace current URL, or push as new URL
    // - support array or string as query name(s) to be removed
    // - ex: history.removeQuery('paintRed')
    removeQuery(queryNames, { push = false } = {}) {
        const location = Object.assign({}, api.location);
        queryNames = _.isArray(queryNames) ? queryNames : [queryNames];
        queryNames.forEach(q => delete location.query[q]);
        if (push) api.push(location);
        else api.replace(location);
    }

    // get current redirect param
    getRedirect() {
        return J.urlParam('redirect_url');
    }

    // add redirect param to a given url
    addRedirect(url, param) {
        return J.setUrlParam('redirect_url', param, url)
    }

    // copy current redirect param to a given url
    keepRedirect(url) {
        return J.setUrlParam('redirect_url', this.getRedirect(), url);
    }

    // go to <url>, then upon completion, go back to current page
    // so, internally it adds redirect_url to the url, then on that page, it must
    // write logic that makes user go back upon completion
    pushAndComeBackOnComplete(url, useReplace) {
        var goTo = this.addRedirect(url, api.location.pathname);
        if (useReplace) api.replace(goTo);
        else api.push(goTo);
    }

    // check if previous url starts with a particular pathname, ex: /meal
    previousLocationStartsWith(pathname) {
        return (
            _.isObject(this.previousLocation) &&
            _.isString(this.previousLocation.pathname) &&
            '/' + this.previousLocation.pathname.split('/')[1] === pathname
        );
    }

    getFirstPathInUrl() {
        return '/' + api.location.pathname.split('/')[1];
    }

}



export default new auto(History)()