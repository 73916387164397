import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Observer } from "mobx-react";
import { observable, computed, action, runInAction } from "mobx";
import _ from "lodash";
import $ from "jquery";
import J from 'utils/standard';
import { auto } from 'utils/mobx'
import "./style.css";




// ---------------------- API ---------------------- //
class AjaxProgress {
    
    // current percent
    percent = 0;
    // when true, bar will be animated to the new percentage
    // when false, bar will be set instantly to the new percentage
    enableTransition = false;
    // timer to increase bar slowly while waiting for server to process request
    intervalId = null;

    // set a percent (ex: 0.34)
    set = (percent) => {
        if (percent > this.percent) {
            this.percent = percent;
        }
        this.increaseSlowly();
    }

    // start 
    start = async () => {
        // immiediately it to 0 first
        await runInAction(() => {
            this.enableTransition = false;
            this.percent = 0;
        }); 
        // then animate it to a random position around the middle of screen
        await runInAction(() => {
            this.enableTransition = true;
            this.percent = _.random(0.2, 0.6);
        });
        // every 400ms, increase it a little bit, so user doesn't think the website is stuck
        this.increaseSlowly();
    }

    // increase automatically a little bit at every interval, so user think server is still active
    increaseSlowly = () => {
        this.stopAutoIncrease();
        this.intervalId = setInterval(() => {
            runInAction(() => {
                // increase until 90%, then stop
                if (this.percent < 0.9) this.percent += _.random(0.008, 0.025);
            });
        }, 800);
    }

    // stop increasing automatically
    stopAutoIncrease = () => {
        clearInterval(this.intervalId);
    }

    // done 
    done = () => {
        this.stopAutoIncrease();
        this.enableTransition = true;
        this.percent = 1;
    }
}

const ajaxProgress = new auto(AjaxProgress)()



// ------------------- Component ------------------- //
class AjaxProgressUI extends Component {
    $p = () => $(ReactDOM.findDOMNode(this));

    // show or hide depending on progress
    componentDidUpdate(){
        if (ajaxProgress.percent < 1){
            this.$p().show();
        }
        else if (ajaxProgress.percent >= 1){
            setTimeout(() => {
                this.$p().animatedHide('fadeOut', 600);
            }, 600);
        }
    }

    render() {
        const { percent, enableTransition } = ajaxProgress;
        const percentage = percent * 100;
        const barStyle = {
            ...J.vendorPrefixed('transform', `translate3d(-${100 - percentage}%, 0px, 0px)`),
            ...J.vendorPrefixed('transition', enableTransition ? `transform 400ms ease` : 'none'),
        };
        return (
            <Observer>{() => (
                <div id='ajax-progress' style={barStyle}>
                    <div className="shadow"></div>
                </div>
            )}</Observer>
        )
    }
}


// export UI
export default AjaxProgressUI;

// export API methods
export { ajaxProgress }