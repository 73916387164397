import _ from "lodash";
import J from 'utils/standard';
import { toJS, runInAction } from "mobx";
import { auto } from 'utils/mobx'


class HireStore {

    // configs
    initial_loading = false // fetch saved form from se
    current_step = 1
    total_steps = 3
    get step_percent(){
        return this.current_step / this.total_steps
    }
    
    // final json
    _id = ''
    selected_main_skills = []
    selected_other_skills = []
    get skills(){ return  _.union(this.selected_main_skills, this.selected_other_skills) }
    worker_type = ''
    company_size = ''
    company_website = ''
    contact_email = ''
    contact_name = ''
    contact_phone = ''              // ex: "+1-4081234567"
    contact_phone_beautified = ''   // ex: "+1 (408) 123-4567"
    contact_phone_country = ''      // ex: "Canada", "Ukraine"
    contact_phone_country_code = '' // ex: "1", "86"
    form_completed = false

    // worker_type = 'Full-Time'
    // company_size = '1-3'
    // company_website = ''
    // contact_email = 'joesmithz2008@gmail.com'
    // contact_name = 'Joe Smith'
    // contact_phone = '+1-4084695854'             
    // contact_phone_beautified = '+1 (408) 469-5854'   
    // contact_phone_country = 'Canada'     
    // contact_phone_country_code = '1'
    // form_completed = false
    


    // change step
    setStep = step => {
        this.current_step = step
    }

    // get saved form from server
    fetchSavedForm = async () => {
        // reset existing state (ex: after completing the form and going back
        // to this page again, we should reset to empty, bcz once form is completed,
        // user should be able to create ANOTHER application)
        await this.reset()
        // load from server
        this.initial_loading = true
        const { doc, error } = await J.post('/consult/fetch')
        if (!doc) return
        delete doc.skills // delete the getter field, it cannot be set
        Object.assign(this, doc) // override current state
    }

    // save each step's data on-the-fly to server
    saveToServer = async () => {
        const payload = this
        // ajax
        const { doc, error } = await J.post('/consult/update', payload)
        if (error) console.error(error)
        // if returned an "_id" (happens when we're creating a new form),
        // save it, so further requests will carry it, and server can identify 
        // current form
        if (doc && doc._id) {
            this._id = doc._id
        }
    }
}

export default new auto(HireStore)()