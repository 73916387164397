
import React, { useState, useEffect } from "react";
import { useMatch, useNavigate, matchPath, useLocation } from 'react-router-dom'
import cls from 'classnames';
import _ from "lodash";
import { Link as ReactRouterLink } from 'react-router-dom'
import Track from 'utils/track'

// Create a wrapper so we can add tracking onto it
const Button = ({ 
    to, 
    className, 
    type='primary', // or 'default'
    children, 
    onClick=()=>{}, 
    marker, 
    ...props 
}) => {
    const handleClick = e => {
        Track.click(e, marker)
        onClick(e)
    }

    const btn = (
        <button 
            {...props}
            className={cls('ui-btn', type, className)} 
            onClick={handleClick}
        >
            {children}
        </button>
    )

    if (to){
        return (
            <ReactRouterLink to={to}>
                {btn}
            </ReactRouterLink>
        )
    }

    else {
        return btn
    }
}


export default Button