import React, { useState } from "react";
import _ from "lodash";
import Logo from 'components/logo'
import Link from 'ui/link'
import "./style.css";



const Footer = () => {
    return (
        <footer className='footer' data-marker='footer'>
            <div className='wrap'>
                <div className='upper'>
                    <div className='col first'>
                        <Logo white />
                        <div className='slogan'>Hire world-class remote developers and teams.</div>
                    </div>
                    <div className='col second'>
                        <Link className='link' to='/how-it-works'>How It Works</Link>
                        <Link className='link' to='/hire'>Hire Developers</Link>
                        <Link className='link' to='/apply'>Apply as Engineer</Link>
                    </div>
                    <div className='col last'>
                        <Link className='link' to='/faq'>Read FAQs</Link>
                        <Link className='link' to='/about'>About Us</Link>
                        <Link className='link' to='/terms'>Terms of Use</Link>
                        <Link className='link' to='/privacy'>Privacy Policy</Link>
                    </div>
                </div>
                <div className='bar'></div>
                <div className='bottom'>
                    © {new Date().getFullYear()} Peerku, Inc. All rights reserved
                </div>
            </div>
        </footer>
    )
}




export default Footer;