import { useState, useRef, useEffect, useLayoutEffect } from 'react'


export const useClickOutside = ({ except = [], run = ()=>{} }) => {
    const ref = useRef()
    // ref is current element
    const handleEvent = e => {
        // so if current element DOES NOT contains the clicked element,
        // then the click is outside
        if (ref.current && !ref.current.contains(e.target)) {
            // if clicked element match any selector in except, or is a child of 
            // any except... then do nothing
            for (const selector of except){
                if (e.target.closest(selector)){
                   return
                }
            }
            run()
        }
    }
    useEffect(() => {
        document.addEventListener('click', handleEvent)
        return () => {
            document.removeEventListener('click', handleEvent)
        }
    }, [])
    return [ref]
}



// scroll page to top
//  - executes before component mounts
//  - reason is to avoid "flash", aka component is loaded, scroll is 
//    first at bottom, then jumps up
export const useScrollToTop = () => {
    useLayoutEffect(()=> {
        window.scrollTo(0, 0)
    }, [])
}





