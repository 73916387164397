import React, { useEffect } from "react";
import { useLocation, matchPath, useMatch, useNavigate, useParams } from 'react-router-dom'
import Topbar from 'components/topbar';
import Footer from 'components/footer';
import J from 'utils/standard'
import Track from 'utils/track'
import "./style.css";


/**
 * Wrapper around the entire application.
 * 
 * Doing so has a number of benefits
 * 	- allowing you to detect React-Router URL change and store URL histories.
 *  - mount singleton components on the toppest level of hierarchy and allow 
 *    other components to directly interact with them via their exposed API methods
 * 
 */

const App = ({ children }) => {

	const location = useLocation()
	const navigate = useNavigate()

	// pages that needs complete customization (i.e. custom topbar & no footer)
	const is_special = ['/hire/*', '/apply/*'].map(useMatch).some(Boolean)

	// whenever URL changes (or first mounts)
	useEffect(() => {

		// if current url contains "referral_from" param, it means user opened our  
		// website from an referral link
		//  - ex: http://localhost:3000/how-it-works?referral_from=email&text=half-the-cost
		//  - this could be an email we sent to clients, or an instagram/fb ad we placed
		//  - so we track this event
		//  - then, we revert back to original URL, so URL looks clean to user
		const params = J.getQueryParams()
		if (params.referral_from){
			// 1. get original URL, i.e. remove all URL params that come after "referral_from"
			const original_url = J.trimAllQueryParamsAfter('referral_from')
			// 2. reset to original URL
			navigate(original_url, { replace: true })
			// 3. track coming from referral (since this is executed after url
			//	  changes to original, we'll store the original url instead of
			//	  url having a bunch of referral related data)
			Track.comeFromReferral(params)
			return
		}

		// track going to that page
		Track.goToUrl()

	}, [location])


	return (
		<div className='app'>
			{is_special ? null : <Topbar />}
			{children}
			{is_special ? null : <Footer />}
		</div>
	)
}

export default App;
