import _ from "lodash";
import J from 'utils/standard';
import { toJS, runInAction } from "mobx";
import { auto } from 'utils/mobx'


class ApplyStore {

    // configs
    initial_loading = false // fetch saved form from se
    current_step = 1
    total_steps = 5
    get step_percent(){
        return this.current_step / this.total_steps
    }
    
    // final json
    _id = ''
    full_name = ''
    email = ''
    phone = '' // ex: "+1-4081234567"
    phone_country = ''  // ex: "Canada"
    role = ''
    earliest_start_time = ''
    desired_hourly_rate = ''
    work_hours_per_week = ''
    residing_country = '' // ex: 'Canada'
    resume_metadata = null 
    resume_url = ''
    linkedin_url = ''
    github_url = ''
    form_completed = false


    // // test user
    // full_name = 'John Doe'
    // email = 'johndoe@gmail.com'
    // phone = ''
    // phone_country = ''

    // role = 'Full-Stack Engineer'
    // earliest_start_time = 'Within a month'
    // desired_hourly_rate = 40
    // work_hours_per_week = '20 hours / week'
    


    // change step
    setStep = step => {
        this.current_step = step
    }

    // get saved form from server
    fetchSavedForm = async () => {
        // reset existing state (ex: after completing the form and going back
        // to this page again, we should reset to empty, bcz once form is completed,
        // user should be able to create ANOTHER application)
        await this.reset()
        // load from server
        this.initial_loading = true
        const { doc, error } = await J.post('/apply/fetch')
        if (!doc) return
        Object.assign(this, doc) // override current state
    }

    // save each step's data on-the-fly to server
    saveToServer = async () => {
        const payload = this
        // ajax
        const { doc, error } = await J.post('/apply/update', payload)
        if (error) console.error(error)
        // if returned an "_id" (happens when we're creating a new form),
        // save it, so further requests will carry it, and server can identify 
        // current form
        if (doc && doc._id) {
            this._id = doc._id
        }
    }
}

export default new auto(ApplyStore)()