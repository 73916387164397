import React, { useState, useEffect } from "react";
import _ from "lodash";
import cls from "classnames";
import "./style.css";
import J from "utils/standard";


const existing_file_icons = ['pdf', 'txt', 'doc', 'docm', 'docx']
const doc_alias = ['doc', 'docm', 'docx'] // all word docs

// get extension of a file
function getExtension(filename){
    if (filename.indexOf('.') >= 0) return filename.split('.').pop();
    else return '';
}

// determine file icon according to file extension
function fileIcon(url){
    var ext = getExtension(url).toLowerCase();
    var icon;
    if (doc_alias.includes(ext)){
        icon = '/img/file-doc.svg';
    }
    else if (existing_file_icons.includes(ext)){
        icon = `/img/file-${ext}.svg`
    }
    else {
        icon = '/img/file-generic.svg';
    }
    return icon;
}

// beautify file size
function fileSize(bytes){
    var b  = 1;
    var kb = Math.pow(10, 3);
    var mb = Math.pow(10, 6);
    var gb = Math.pow(10, 9);
    var tb = Math.pow(10, 12);
    if (bytes < kb) return J.round(bytes/b,  2) + ' B';
    if (bytes < mb) return J.round(bytes/kb, 2) + ' KB';
    if (bytes < gb) return J.round(bytes/mb, 2) + ' MB';
    if (bytes < tb) return J.round(bytes/gb, 2) + ' GB';
    return J.round(bytes/tb, 2) + ' TB';
}





const FileUpload = ({ 
    valid_extensions = [],
    default_file_metadata = null,
    onUploadSuccess = ()=>{},
    onRemoveFile = ()=>{},
}) => {
    const [tmpid] = useState(J.uuid())
    // upload progress
    const [uploading, setUploading] = useState(false)
    const [uploadPercent, setUploadPercent] = useState(0)
    // the final file object
    //  - if returned from server, it contains "name", "size"
    //  - if chosen from filesystem, it contains "name", "size"
    const [file, setFile] = useState(default_file_metadata) 

    // remove file
    const removeFile = () => {
        setFile(null)
        onRemoveFile()
        // TODO: cancel upload 
    }

    // upon selecting file from filesystem, perform upload
    const performUpload = async e => {
        // get file blob
        if (!e.target || _.isEmpty(e.target.files)) {
            console.error('invalid file');
			return
		}
        const file_blob = e.target.files[0];
        // validate extension
        const ext = getExtension(file_blob.name)
        const valid = _.isString(ext) && valid_extensions.includes(ext.toLowerCase()) 
        if (!valid) {
            const msg = `File must be in ${valid_extensions.map(_.toUpper).join(', ')} format. Please try again.`
            return alert(msg);
        }
        // display file information
        setFile(file_blob)
        // display uploading bar
        setUploading(true)
        // start uploading
        const {url, name, size, error} = await J.uploadFile('/upload/resume', file_blob, {
            // keep track of upload %
            onProgress: num => setUploadPercent(num)
        })
        setUploading(false)
        // upload success
        if (url){
            onUploadSuccess({
                url,
                metadata: {name, size}
            })
        }
        // fail, remove file so user can try again
        else {
            alert(error || 'Upload has failed.')
            removeFile()
        }
    }
    
    // useEffect(() => {
    //     setFile({ name: 'lol.pdf', size: 1000 })
    // }, [])


    return (
        <div className='file-upload'>
            {/* user already have a previously uploaded url, 
                or he selected a local file in his filesystem */}
            {/* must check name as well because file can be an empty json */}
            {(file && file.name) ? (
                <div className='file-exist wrap'>
                    <div className='left'>
                        <div className='icon center-contained' 
                            style={J.bgUrl(fileIcon(file.name))}
                        />
                        <div className='info'>
                            <div className='name'>{file.name}</div>
                            <div className='misc'>
                                {uploading ? (
                                    <div className='load'>
                                        <div className='inner' style={{ width: `${uploadPercent * 100}%` }}></div>
                                    </div>
                                ) : (
                                    <div className='size'>{fileSize(file.size)}</div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='close center-contained' 
                        style={J.bgUrl('/img/close.svg')}
                        onClick={removeFile}    
                    />
                </div>
            ) : (
                <label className='file-empty wrap' htmlFor={tmpid}>
                    <input id={tmpid} type="file" 
                        accept={valid_extensions.map(ext => `.${ext}`).join(',')}
                        onChange={performUpload}
                    />
                    <div className='mock'>
                        <div className='icon center-contained' style={J.bgUrl('/img/upload.svg')}></div>
                        <div className='text'>Click to Upload</div>
                    </div>
                </label>
            )}
            
        </div>
    )
}




export default FileUpload