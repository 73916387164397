import React, { useState, useEffect } from "react";
import { useMatch, useNavigate, matchPath, useLocation } from 'react-router-dom'
import { observer } from "mobx-react";
import cls from 'classnames';
import _ from "lodash";
import { viewport, meStore, hireStore } from 'stores'
import J from 'utils/standard';
import Track from 'utils/track'
import { useScrollToTop } from 'utils/hooks'
import FullScreenLoader from 'ui/full-screen-loader'
import PhoneInput from 'ui/phone-input'
import Select from 'ui/select'
import Button from 'ui/button'
import Link from 'ui/link'
import PlainArticle from 'components/plain-article'
import "./style.css";


const Privacy = () => {
    useScrollToTop()
    const { our_contact_email } = meStore.constants
    const email = <a href={`mailto:${our_contact_email}`}>{our_contact_email}</a>

    const props = {
        title: 'Privacy Policy',
        subtitle: `Last Revision: 2019/11/01`,
    }
    
    return (
        <PlainArticle {...props}>
            <p>
                We at Peerku, Inc. ("Peerku") know you care about how your personal 
                information is used and shared, and we take your privacy seriously. Please 
                read the following to learn more about our Privacy Policy. By using or 
                accessing the Services in any manner, you acknowledge that you accept the 
                practices and policies outlined in this Privacy Policy, and you hereby 
                consent that we will collect, use, and share your information in the 
                following ways.
            </p>
            <p>
                Remember that your use of Peerku's Services is at all times subject 
                to the Terms of Use, which incorporates this Privacy Policy. Any terms we 
                use in this Policy without defining them have the definitions given to 
                them in the <Link to='/terms'>Terms of Use</Link>.
            </p>

            <h3>Scope Of This Privacy Policy</h3>
            <p>
                This Privacy Policy covers our treatment of personally identifiable 
                information ("Personal Information") that we gather when you are 
                accessing or using our Services, but not to the practices of companies 
                we don't own or control, or people that we don’t manage. We gather 
                various types of Personal Information from our users, as explained 
                in more detail below, and we use this Personal Information internally 
                in connection with our Services, including to personalize, provide, 
                and improve our services, to contact you and allow other users to 
                contact you, to fulfill your requests for certain products and 
                services, and to analyze how you use the Services. In certain cases, 
                we may also share some Personal Information with third parties, but 
                only as described below.
            </p>
            <p>
                This Privacy Policy covers our treatment of personally identifiable 
                information ("Personal Information") that we gather when you are 
                accessing or using our Services, but not to the practices of companies 
                we don't own or control, or people that we don’t manage. We gather 
                various types of Personal Information from our users, as explained 
                in more detail below, and we use this Personal Information internally 
                in connection with our Services, including to personalize, provide, 
                and improve our services, to contact you and allow other users to 
                contact you, to fulfill your requests for certain products and 
                services, and to analyze how you use the Services. In certain cases, 
                we may also share some Personal Information with third parties, but 
                only as described below.
            </p>
            <p>
                As noted in the Terms of Use, we do not knowingly collect or solicit 
                personal information from anyone under the age of 13. If you are under 
                13, please do not attempt to register for the Services or send any 
                personal information about yourself to us. If we learn that we have 
                collected personal information from a child under age 13, we will 
                delete that information as quickly as possible. If you believe that 
                a child under 13 may have provided us personal information, please 
                contact us at {email}.
            </p>

            <h3>Updates To This Privacy Policy</h3>
            <p>
                We're constantly trying to improve our Services, so we may need to 
                change this Privacy Policy from time to time. We will notify you of 
                any changes by posting the new Privacy Policy on the Site. You are 
                advised to review this Privacy Policy periodically for any changes. 
                Please note that if you've opted not to receive legal notice emails 
                from us (or you haven't provided us with your email address), those 
                legal notices will still govern your use of the Services, and you 
                are still responsible for reading and understanding them. If you use 
                the Services after any changes to the Privacy Policy have been posted, 
                that means you agree to all of the changes.
            </p>

            <h3>Information We Collect and Receive</h3>
            <p></p>
            <h4>Information You Provide to Us</h4>
            <p>
                We receive and store any information you knowingly provide to us. For 
                example, if you sign up to receive communications from us or submit 
                applications for job openings, we may collect Personal Information 
                such as your name, company, email, phone number, and resume. Certain 
                information may be required to register with us or to take advantage 
                of some of our features.
            </p>
            <p>
                We may communicate with you if you've provided us the means to do so. 
                For example, if you’ve given us your email address, we may send you 
                promotional email offers on behalf of other businesses, or email you 
                about your use of the Services. Also, we may receive a confirmation 
                when you open an email from us.This confirmation helps us make our 
                communications with you more interesting and improve our services. 
                If you do not want to receive communications from us, please indicate 
                your preference by sending an email with your request to {email}.
            </p>
            <h4>Information Collected Automatically</h4>
            <p>
                Whenever you interact with our Services, we automatically receive 
                and record information on our server logs from your browser or 
                device, which may include your IP address, geolocation data, device 
                identification, “cookie” information, the type of browser and/or 
                device you’re using to access our Services, and the page or feature 
                you requested. “Cookies” are identifiers we transfer to your browser 
                or device that allow us to recognize your browser or device and tell 
                us how and when pages and features in our Services are visited and 
                by how many people. You may be able to change the preferences on your 
                browser or device to prevent or limit your device’s acceptance of 
                cookies, but this may prevent you from taking advantage of some of 
                our features.
            </p>
            <p>
                Also, if you click on a link to a third party website or service, a 
                third party may also transmit cookies to you. Again, this Privacy 
                Policy does not cover the use of cookies by any third parties, and 
                we aren't responsible for their privacy policies and practices. 
                Please be aware that cookies placed by third parties may continue 
                to track your activities online even after you have left our Services, 
                and those third parties may not honor "Do Not Track" requests you 
                have set using your browser or device.
            </p>
            <p>
                We may use this data to customize content for you that we think you 
                might like, based on your usage patterns. We may also use it to 
                improve the Services – for example, this data can tell us how often 
                users use a particular feature of the Services, and we can use that 
                knowledge to make the Services interesting to as many users as 
                possible.
            </p>

            <h3>Updating and Deleting Your Information</h3>
            <p>
                You may be able to add, update, or delete information at anytime on our 
                electronic platform. When you delete your account from our website, we 
                remove all personal data we have on record. This means all account 
                deletions are permanent. We may retain certain data for a period of
                time, as required by law, or if there are ongoing payment disputes. 
                For example, we retain tax documentation for technical providers due 
                to legal obligations.
            </p>

            <h3>Data Storage, Retention, and Security</h3>
            <p>
                Your Personal Information is password protected and our main servers 
                are locked and hosted by leading third-party vendors, such as Amazon
                Web Services. Access to the host environment is secure. We maintain 
                two types of logs: server logs and event logs. By using our Services 
                or Site, you authorize us to transfer, store, and use your information 
                in the United States and any other country where we operate.
            </p>
            <p>
                We may retain personal information under circumstances including but not 
                limited to: when we have legal obligations that retain this data; if we 
                suspect there is fraud or scam; during an ongoing dispute with a technical
                provider or client.
            </p>
            <p>
                We take the security of your personal information very seriously, but no 
                method of transmission or storage is 100 percent secure, e.g. hardware or 
                software failure, or other factors. While we strive to use commercially 
                acceptable means to protect your personal information, we cannot guarantee 
                its absolute security.
            </p>

            <h3>More Questions About This Policy</h3>
            <p>
                If you have any questions or concerns regarding our privacy policies, 
                please send us a detailed message to {email}, and we will try to resolve 
                your concerns.
            </p>
        </PlainArticle>
    )
}




export default observer(Privacy);