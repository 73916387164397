import React, { useState } from "react";
import _ from "lodash";
import { observer } from 'mobx-react'
import { viewport } from 'stores'
import Link from 'ui/link'
import "./style.css";



const Logo = observer(({ white }) => {
    const { is_mobile } = viewport
    const brand = 'Peerku'
    const prefix = brand.slice(0, -2)
    const suffix = brand.slice(-2)
    return (
        <Link to='/' className='logo center-contained' data-mobile={is_mobile} data-white={white}>
            <div className='img center-contained'></div>
            <span className='txt'>
                {white ? <>
                    <span className='peer'>{prefix}</span>
                    <span className='ku'>{suffix}</span>
                </> : brand}
            </span>
        </Link>
    )
})




export default Logo;