/**
 * Contains a bunch of helper functions.
 * 
 */

import $ from 'jquery';
import axios from 'axios';
import _ from 'lodash';
import { ajaxProgress } from 'ui/ajax-progress';
import consoleColor from 'utils/console-color';


// export our module
const J = {};



// Default HTTP request configs
const httpConfig = {
    baseURL: '/api',
    customHeaders: () => {
        return {
            'x-duid': J.getDeviceId()
        }
    }
};

// POST wrapper
J.post = async (url, payload = {}, options = { ajaxBar: true, headers: {} }) => {
    const method = 'POST';
    let responseBody;
    let responseStatus;

    if (options.ajaxBar) ajaxProgress.start();

    // log start
    const printedPayload = payload instanceof FormData ? J.formDataToJson(payload) : J.deepClone(payload);
    const sensitiveFields = ['password'];
    J.asteriskifyFields(printedPayload, sensitiveFields); // prevent sensitive info from printed out
    consoleColor(`<-- [c="color:darkgray;font-style:italic"]${method}[c] [c="color:deepskyblue;font-weight:bold"]${url}[c]`, printedPayload);

    try { 
        const { data, status } = await axios({
            method,
            url,
            data: payload,
            responseType: 'json',
            withCredentials: true, // needed to send cookies with cross-origin ajax calls.
            crossDomain: true,
            onDownloadProgress: e => {
                if (options.ajaxBar) ajaxProgress.set(e.loaded / e.total);
            },
            ...httpConfig,
            ...options,
            headers: { 
                ...httpConfig.headers, 
                ...(httpConfig.customHeaders ? httpConfig.customHeaders() : {}),
                ...options.headers 
            },
        });
        responseBody = data;
        responseStatus = status;
    } 
    catch (e) {
        const { data, status } = e.response || {};
        const serverErrorMessage = data ? data.error : ''; // error message from server
        const axiosErrorMessage = e.message;  // error message from browser 
        const error = serverErrorMessage || axiosErrorMessage || 'Error occured';
        responseBody = { error };
        responseStatus = status;
    }

    if (options.ajaxBar) ajaxProgress.done();
    responseBody = responseBody || {};

    // log end
    consoleColor(`--> [c="color:darkgray;font-style:italic"]${method}[c] [c="color:limegreen;font-weight:bold"]${url}[c] _${responseStatus}_`, responseBody);

    return responseBody; // ensure it's not empty, so deconstructing works without crash
};

// Post with form data
J.postFormData = (url, formdata, options) => {
    const { onProgress, ...rest } = options || {}
    return J.post(url, formdata, { 
        headers: { 'Content-Type': 'multipart/form-data' }, 
        ajaxBar: true,
        onUploadProgress: e => {
            const percentage = e.loaded / e.total;
            onProgress(percentage)
        },
        ...rest,
    });
};

// Upload file 
J.uploadFile = (url, file_blob, options) => {
    const formdata = new FormData();
    formdata.append('file_blob', file_blob);
    return J.postFormData(url, formdata, options);
};

// Generate UUID (used as identifier for messaging)
J.uuid = () => {
    var d = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (d + Math.random()*16)%16 | 0;
        d = Math.floor(d/16);
        return (c=='x' ? r : (r&0x3|0x8)).toString(16);
    });
    return uuid;
};

// wrapper for JSON.parse to catch exception
J.toJSON = (str, { suppressErrorLog = true } = {}) => {
    let result;
    try {
        result = JSON.parse(str);
    } catch(e) {
        if (!suppressErrorLog) console.error('J.toJSON err:', str);
    }
    return result;
};

// localstorage without JSON.parse() and JSON.stringify()
J.storeSet = (key, val) => localStorage.setItem(key, JSON.stringify(val));
J.storeGet = (key, default_value) => J.toJSON(localStorage.getItem(key)) || default_value;
J.storeRemove = key => localStorage.removeItem(key);
J.storeClear = () => localStorage.clear();

// Custom localStorage methods
// Push to array
J.storePush = (key, val) => J.storeSet(key, (J.storeGet(key) || []).concat(val));
// Remove from array (using predicate)
J.storePull = (key, ...args) => {
    const arr = J.storeGet(key) || [];
    _.remove(arr, ...args)
    return J.storeSet(key, arr);
};

// get device id
J.getDeviceId = () => J.storeGet('duid')
// set device id 
J.setDeviceId = () => J.storeSet('duid', J.uuid());

// Simulate a fake request
J.fakeRequest = async (delay = 1000) => {
    await J.post('/init-data');
    return new Promise(resolve => {
        setTimeout(() => resolve(), delay);
    });
};

// Deep clone (use this instead of _.cloneDeep() to remove functions, date, 
// unwrap mobx observable, etc, such that it becomes plain JSON)
J.deepClone = obj => {
    return JSON.parse(JSON.stringify(obj));
};

// Convert formdata into Json
J.formDataToJson = formdata => {
    const json = {};
    formdata.forEach((value, key) => {
        json[key] = value;
    });
    return json;
};

// replace json values by asterisks
J.asteriskifyFields = (obj, arrOfKeys) => {
    arrOfKeys.forEach(key => {
        if (obj[key]) obj[key] = String(obj[key]).replace(/./g, '*')
    });
    return obj;
};

// add React-standard vendor prefix to a css property
J.vendorPrefixed = (property, value) => {
    const css = {};
    const Property = _.upperFirst(_.camelCase(property)); // CamelCaseProperty
    css[_.lowerFirst(Property)] = value;
    css["Webkit" + Property] = value;
    css["Moz" + Property] = value;
    css["Ms" + Property] = value;
    css["O" + Property] = value;
    return css;
};


// convert an arr to json, support an array of strings or objects
J.arrToJson = (arr, key = '_id') => {
    const json = {};
    _.each(arr, (item) => {
        if (_.isObject(item) && item[key]) json[item[key]] = item;
        else if (_.isString(item)) json[item] = true;
    });
    return json;
};

// add a list of docs to an existing list, if 2 docs have same <key>, update old doc by new doc
J.addDocs = (existing_docs, new_docs, key = '_id') => {
    const new_docs_hash = J.arrToJson(new_docs, key);
    const updated_docs_hash = {};
    // loop through old docs, if there's an updated version in the new docs, replace it
    existing_docs = _.map(existing_docs, doc => {
        const id = doc[key];
        const updated_doc = new_docs_hash[id];
        if (updated_doc) {
            updated_docs_hash[id] = updated_doc;
            return updated_doc;
        }
        return doc;
    });
    // then for each new doc that is not an update of the old doc, add it to the final array
    new_docs.forEach(doc => {
        const id = doc[key];
        if (!updated_docs_hash[id]){
            existing_docs.push(doc);
        }
    });
    return existing_docs;
};

// check if <new_docs> have docs that <existing_docs> don't have
J.hasNewDocs = (existing_docs, new_docs, key = '_id') => {
    const existing_docs_hash = J.arrToJson(existing_docs);
    // loop through new docs, if there's a doc that doesn't exist in existing doc, return true
    for (let i=0; i<new_docs.length; i++){
        const id = new_docs[i][key];
        if (!existing_docs_hash[id]) return true;
    }
    return false;
};

// extend old docs with new docs, return extended docs
J.extendDocs = (existing_docs, new_docs, key = '_id') => {
    const new_docs_hash = J.arrToJson(new_docs);
    // loop through old docs, if there's an updated version in the new docs, extend it
    return _.map(existing_docs, doc => {
        const updated_doc = new_docs_hash[doc[key]];
        if (updated_doc) return _.extend(doc, updated_doc);
        else return doc;
    });
};

// round a number to n decimals, must return a number
// not only this can round, but it can remove machine imprecision
// ex: 2.75+36+4.31+2.99+3.61 = 49.660000000000004
//     J.round(2.75+36+4.31+2.99+3.61) = 49.66
J.round = (value, decimals = 2) => {
    return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
};


// override default jQuery's .val(), ensure it returns string
const $originalVal = $.fn.val;
$.fn.val = function(){
    return $originalVal.call(this) || '';
};

// return true if div is scrolled to bottom
$.fn.scrolledToBottom = function(tolerance = 5){
    if (this.scrollTop() + this.innerHeight() >= this.get(0).scrollHeight - tolerance) {
        return true;
    }
    else return false;
};

// Get height of hidden elements
$.fn.getHeight = function(){
    var previousCss  = this.attr("style");
    this.css({
        position:   'absolute',
        visibility: 'hidden',
        display:    'block'
    });
    var optionHeight = this.height();
    this.attr("style", previousCss ? previousCss : "");
    return optionHeight;
};

// reset DOM element
$.fn.reset = function(){
    this.replaceWith(this.clone());
};

// Determine if element is visible in viewport
J.isVisibleInViewport = el => {
    if (typeof $ === "function" && el instanceof $) el = el[0];
    if (!el || !el.getBoundingClientRect) return;
    var rect = el.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /*or $(window).height() */
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */
    );
};

/** Get URL parameters. Ex: $.urlParam('id'); will output 6 */
J.urlParam = (name, url) => {
    url = url || window.location.href;
    var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(url);
    if (results==null){
       return null;
    } else { return results[1] || 0; }
};

// update/add url query string
// - not supplying a value will remove the parameter
// - supplying one will add/update the parameter
// - if no URL is supplied, it will be grabbed everything after domain
J.setUrlParam = (key, value, url) => {
    if (!url) url = window.location.pathname + window.location.search + window.location.hash;
    var re = new RegExp("([?&])" + key + "=.*?(&|#|$)(.*)", "gi"),
        hash;

    if (re.test(url)) {
        if (typeof value !== 'undefined' && value !== null)
            return url.replace(re, '$1' + key + "=" + value + '$2$3');
        else {
            hash = url.split('#');
            url = hash[0].replace(re, '$1$3').replace(/(&|\?)$/, '');
            if (typeof hash[1] !== 'undefined' && hash[1] !== null) 
                url += '#' + hash[1];
            return url;
        }
    }
    else {
        if (typeof value !== 'undefined' && value !== null) {
            var separator = url.indexOf('?') !== -1 ? '&' : '?';
            hash = url.split('#');
            url = hash[0] + separator + key + '=' + value;
            if (typeof hash[1] !== 'undefined' && hash[1] !== null) 
                url += '#' + hash[1];
            return url;
        }
        else
            return url;
    }
};

// Get all query params of URL
//  - example:
//  - getQueryParams('https://peerku.com/hire?a=1&b=2&a=3')
//  - returns { a: ['1', '3' ], b: '2' }
J.getQueryParams = (url = window.location.href) => {
    const json = {}
    for (const [key, val] of new URL(url).searchParams){
        if (!json[key]){
            json[key] = val
        }
        else {
            json[key] = [json[key]]
            json[key].push(val)
        }
    }
    return json
}

// remove URL params that come after starting_param
//  - example:
//  - trimAllQueryParamsAfter('hello', 'https://peerku.com/hire?a=1&b=2&hello=3&c=4&d=5')
//  - returns "/hire?a=1&b=2"
J.trimAllQueryParamsAfter = (starting_param, url = window.location.href) => {
    const location = new URL(url)
    const params = location.searchParams
    let start_deleting = false
    for (const [key, val] of [...params]){
        if (key === starting_param){
            start_deleting = true
        }
        if (start_deleting) {
            params.delete(key)
        }
    }
    return location.pathname + location.search
}

// remove all keys with falsey values 
J.cleanJson = json => {
    const omit = [null, undefined, ''];
    return _.omitBy(json, value => _.includes(omit, value));
};

// compressed image URL from qiniu
J.compressedImageUrl = url => {
    return url + '-t480';
};

// original image URL from qiniu
J.uncompressedImageUrl = url => {
    return String(url).replace('-t480', '');
};

// return background-image url
J.bgUrl = url => (
    url ? { backgroundImage: `url(${url})` } : {}
);

// convert all consecutive space/new lines into a single space, remove leading/trailing space/new lines.
// essentially, we can only have a single space in between any 2 characters.
J.trimAll = s => (
    String(s || '').replace(/\s+/g,' ').replace(/^\s+|\s+$/g,'')
);


// determine if string contains chinese/japanese
J.hasChinese = s => {
    return s && s.match(/[\u3400-\u9FBF]/);
};


// check if string only contains digits
J.onlyContainsDigits = s => {
    return parseInt(s, 10) + '' === s;
};

// check if a string is stringified integer, or is int
J.isStringifiedInteger = str => {
    return Number(str) + '' === String(str);
};

// check if is valid email
J.isValidEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

// check if phone follows the correct format, that is, COUNTRY_CODE-PHONE_NUMBER. 
//  - Ex: canada is +1-5141234567
//  - this DOES NOT check if phone number is valid, for that, use the sms verification 
//    code with twilio or something
J.isCorrectPhone = phone => {
    if (!phone || !_.isString(phone)) return false
    // format is +COUNTRY_CODE-PHONE_NUMBER
    //  - it has to be exactly one +, one -, and both COUNTRY_CODE and PHONE_NUMBER
    //    must be digits, no other characters are allowed
    //  - Ex: +1-5141234567
    return /\+(\d)+-(\d)+/.test(phone)
}


// ---------- helper animate.css functions ----------------

// show, 3rd argument can be option json or callback
$.fn.animatedShow = function(effect, duration, opts={}){
    const onComplete = _.isFunction(opts) ? opts : (opts.onComplete || function(){});
    const durationClass = "animate_duration_" + (duration || '');
    const classes = 'animated ' + durationClass + ' ' + effect;
    this.addClass(classes).show()
    .one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', () => {
        this.removeClass(classes);
        onComplete(this);
    });
    return this;
};

// hide, 3rd argument can be option json or callback
$.fn.animatedHide = function(effect, duration, opts={}){
    const beforeHide = opts.beforeHide || function(){};
    const onComplete = _.isFunction(opts) ? opts : (opts.onComplete || function(){});
    const durationClass = "animate_duration_" + (duration || '');
    const classes = 'animated ' + durationClass + ' ' + effect;
    this.addClass(classes)
    .one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', () => {
        beforeHide();
        this.hide().removeClass(classes);
        onComplete(this);
    });
    return this;
};


// Disable mousewheel scrolling on `<input type=number>` 
$(document).on("wheel", "input[type=number]", function (e) {
    $(this).blur();
});






export default J;

