import _ from "lodash";
import J from 'utils/standard';
import { auto } from 'utils/mobx'


class ViewPort {

    width = window.innerWidth
    height = window.innerHeight

    get is_mobile(){
        return this.width < 768
    }

    get is_tablet(){
        return this.width >= 768 && this.width <= 1024
    }

    get is_desktop(){
        return this.width > 1024
    }

    constructor(){
        window.addEventListener('resize', () => {
            this.width = window.innerWidth
            this.height = window.innerHeight
        })
    }
}

export default new auto(ViewPort)()