import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import cls from 'classnames';
import _ from "lodash";
import J from 'utils/standard';
import { useScrollToTop } from 'utils/hooks'
import { viewport } from 'stores'
import Highlight from 'ui/highlight'
import Button from 'ui/button'
import Link from 'ui/link'
import "./style.css";



const HowItWorks = observer(() => {
    useScrollToTop()

    return (
        <div className='hiw'>
            <div className='section banner'>
                <div className='wrap'>
                    <div className='mark'>> Peerku talent platform</div>
                    <h1>Hire senior remote engineers, pre-vetted and tailored to your need.</h1>
                    <p>We deliver you experienced engineers worldwide, validated by industry veterans from Google and Facebook. Hire talent quickly - in days, not months.</p>
                    <Button to='/hire' marker='howitworks-banner'>START YOUR SEARCH</Button>
                </div>
                <div className='pic center-contained'></div>
            </div>

            <div className='section list one'>
                <div className='wrap'>
                    <div className='txt'>
                        <h2>
                            <Highlight blue>Our Partnership Highlights</Highlight>
                        </h2>
                    </div>
                    <ol>
                        <li>Half the cost of hiring Silicon Valley engineers.</li>
                        <li>Engineers are pre-vetted by Google and Facebook interviewers through multiple extensive deep-dive interviews. Their technical skills are ensured to be L4+ in Silicon Valley standard.</li>
                        <li>Engineers have expertise in Front-end, Back-end, Full-stack, Mobile, DevOps, Machine learning, Data engineers and more.</li>
                        <li>Engineers match your timezone and have a minimum of 4 hours overlap with your workday.</li>
                        <li>Engineers will write daily report of their work.</li>
                        <li>Free to get started, pay engineers only as you go. If you'd like to stop or request different engineers, you can do so at any time.</li>
                        <li>Hire fast, get candidates within 1-3 days.</li>
                    </ol>
                </div>
            </div>

            <div className='section list two'>
                <div className='wrap'>
                    <div className='txt'>
                        <h2>
                            <Highlight yellow>Our Engineer Vetting Process</Highlight>
                        </h2>
                    </div>
                    <ol>
                        <li>4-hour interview conducted by veteran engineers from Google and Facebook to vet candidates.</li>
                        <li>No bullshit test, we evaluate the candidate's actual skills needed in the industry.</li>
                        <li>Deep dive round: for each resume point, ask detailed questions on implementation details, design decisions, along with multiple follow-ups. Through this, we fully examine their technical knowledge's breadth and depth.</li>
                        <li>Live coding round: assess domain-specific skills and code quality and - we want candidates who can ship, not just talk.</li>
                        <li>Behavior round: ensure the candidate has good communication skills and collaborates well in a team environment.</li>
                        <li>Background check after candidate passed all of the above.</li>
                    </ol>
                </div>
            </div>

            <div className='section list three'>
                <div className='wrap'>
                    <div className='txt'>
                        <h2>
                            <Highlight green>Ready to Start? Next Steps</Highlight>
                        </h2>
                    </div>
                    <ol>
                        <li>Let us know about your company and the engineers you're looking to hire.</li>
                        <li>Schedule a kick-off call with us.</li>
                        <li>Review the engineers we selected for you, and decide if you wish to start.</li>
                        <li>Once you decide to start, we'll help take care of all the paperwork and payment processing.</li>
                        <li>If you wish to hire more than one engineer, our hiring consultant can help you fill multiple roles or build entire remote teams.</li>
                    </ol>
                    <div className='btn-container'>
                        <Button to='/hire' marker='howitworks-bottom'>
                            HIRE ENGINEERS NOW
                        </Button>
                    </div>
                    
                </div>
            </div>

        </div>
    )
})




export default HowItWorks;