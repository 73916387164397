import J from 'utils/standard'

// export API
const Track = {}


// ------------------------- helpers ------------------------- //






// --------------------------- API --------------------------- //

// Track arbitrary user actions
Track.action = (action, related_data) => {
    return J.post('/track', { 
        action, 
        related_data, 
        current_url: window.location.href,
    })
}


// Visit URL
//  - no need to explicitly include current url
//  - because request will already contain "Referer" header which is current url
//  - store viewport width & height, so we get a sense of how big the screen is
Track.goToUrl = () => {
    return Track.action('navigated_to_url', { 
        screen_width: window.innerWidth,
        screen_height: window.innerHeight,
    })
}



// User opened our website from referral link
//  - ex: https://peerku.com/how-it-works?referral_from=email&text=half-the-cost
//  - this could be an email we sent to clients, or an instagram/fb ad we placed
//  - related data are extracted from URL params
//  - after that, we'll remove those params from URL, it URL looks clean to user
Track.comeFromReferral = ({ referral_from, ...rest }) => {
    return Track.action('come_from_referral', { 
        referral_from,   // mandatory param, ex: "email", "instagram", "fb"
        ...rest          // other params can be customized in the url
    })
}



// Click on button, link (inside our website)
Track.click = (e, marker) => {
    const el = e.target
    const tag = el.tagName ? el.tagName.toLowerCase() : ''
    if (!['button', 'a'].includes(tag)) return;
    // find closest "marking" parent that indicates its place on the page
    const mark_el = el.closest('[data-marker]')
    const link_el = el.closest('a[href]')
    const related_data = {
        text: el.textContent,
        placed_at: marker || (mark_el ? mark_el.getAttribute('data-marker') : undefined),
        link_to: link_el ? link_el.getAttribute('href') : undefined,
    }
    if (tag === 'button'){
        return Track.action('click_on_button', related_data)
    }
    if (tag === 'a'){
        return Track.action('click_on_link', related_data)
    }
}


export default Track