import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import init from 'utils/init';

// CSS
import 'react-select/dist/react-select.css'  // react virtual select
import 'react-virtualized-select/styles.css' // react virtual select
import 'react-phone-input-2/lib/bootstrap.css' // react phone input
import 'ui/standard.css'; // Global CSS affecting all elements
import 'ui/override.css'; // Override imported lib's CSS

// top-level wrapper around pages
import App from "pages/app";

// pages
import Company from "pages/company";
import HowItWorks from "pages/how-it-works";
import Hire from "pages/hire";
import Apply from "pages/apply";
import Privacy from "pages/privacy";
import Terms from "pages/terms";
import FAQ from "pages/faq";
import About from "pages/about";




// ----------------------- launch app -----------------------

// 1) fetch initial data
init(onRender => {

    // 2) Render react
    ReactDOM.render(
        <BrowserRouter>
            {/* App is the toppest-level component, wrapping our entire app. */}
            <App> 
                {/* all the pages */}
                <Routes>
                    <Route path="/" element={<Company/>} />
                    <Route path="/company" element={<Company/>} />
                    <Route path="/how-it-works" element={<HowItWorks/>} />
                    <Route path="/hire/*" element={<Hire/>} />
                    <Route path="/apply/*" element={<Apply/>} />
                    <Route path="/privacy" element={<Privacy/>} />
                    <Route path="/terms" element={<Terms/>} />
                    <Route path="/faq" element={<FAQ/>} />
                    <Route path="/about" element={<About/>} />
                </Routes>
            </App>
        </BrowserRouter>,
        document.getElementById('root'),
        onRender, 
    );
});



