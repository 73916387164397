import React, { useState, useEffect } from "react";
import { useMatch, useNavigate, matchPath, useLocation } from 'react-router-dom'
import { observer } from "mobx-react";
import cls from 'classnames';
import _ from "lodash";
import { viewport, meStore, applyStore } from 'stores'
import J from 'utils/standard';
import Track from 'utils/track'
import { useScrollToTop } from 'utils/hooks'
import FullScreenLoader from 'ui/full-screen-loader'
import PhoneInput from 'ui/phone-input'
import Select from 'ui/select'
import Button from 'ui/button'
import Highlight from 'ui/highlight'
import Logo from 'components/logo'
import FileUpload from 'components/file-upload'
import "./style.css";



const Step1 = observer(() => {
    useScrollToTop()
    const navigate = useNavigate()

    // mobx store
    const { saveToServer } = applyStore

    // submit condition
    const canSubmit = () => {
        return true
    }

    // click submit
    const performSubmit = async () => {
        saveToServer()
        Track.action('engineer_form_step1_done')
        navigate('/apply/step2')
    }

    const highlight = {
        margin: 0,
        bottom: -1,
        height: '0px',
    }

    return (
        <div className='step s1'>
            <h1>Find Great Remote Jobs</h1>
            <h2><Highlight green {...highlight}>Do work, from anywhere.</Highlight></h2>
            <p>
                Peerku offers unmatched opportunities for remote engineers 
                around the world. No relocation required. 
            </p>
            <br/><br/>

            <h2><Highlight blue {...highlight}>Flexible Work Hours</Highlight></h2>
            <p>
                Full-time or Part-time. Find work that best suits for your time 
                and interests. Apply to 
                Full-Stack, Front-End, Back-End, iOS, Android, Data Engineering, 
                Machine Learning, and other software engineering jobs.
            </p>
            <br/><br/>

            <h2><Highlight yellow {...highlight}>Competitive Salary</Highlight></h2>
            <p>
                Peerku offers better pay compared to local companies.
                Get paid well, and grow your career with top companies from Silicon Valley 
                startups to Fortune 500.
            </p>
            
                
            <br/><br/>

            <h2><Highlight blue {...highlight}>About Peerku</Highlight></h2>
            <p>
                Peerku is based in San Francisco, California, US. We match engineers 
                around the world with innovative companies. We recruit, support and  
                nurture engineers so you can work for dynamic companies from the 
                comfort of a workspace near you. Our mission is to help engineers  
                find their dream remote job, fast.
            </p>
                
            <div className='foot'>
                <div className='inner'>
                    <Button onClick={performSubmit} disabled={!canSubmit()}>
                        APPLY AS AN ENGINEER
                    </Button>
                </div>
            </div>
        </div>
    )
})






const Step2 = observer(() => {
    useScrollToTop()
    const navigate = useNavigate()

    // fetched from server
    const { all_company_sizes = [] } = meStore.constants

    // mobx store
    const { 
        full_name, email, phone,
        saveToServer, setStore,
    } = applyStore

    // submit condition
    const canSubmit = () => {
        // all these fields must be filled
        const mandatory = { full_name, email }
        for (const [key, val] of Object.entries(mandatory)){
            if (!val) return false 
        }
        // validate email 
        if (!J.isValidEmail(email)) return false
        return true
    }

    // click submit
    const performSubmit = async () => {
        saveToServer()
        Track.action('engineer_form_step2_done')
        navigate('/apply/step3')
    }

    return (
        <div className='step s2'>
            <h2>Become a Peerku Engineer</h2>
            <h3>Let us know a bit about yourself by filling out this quick form.</h3>
            <div className='content'>
                <div className='ui-block'>
                    <label className='ui-label required'>Full name</label>
                    <input className='ui-input' placeholder='John Doe' 
                        value={full_name}
                        onChange={e => setStore({ full_name: e.target.value })} />
                </div>
                <div className='ui-block'>
                    <label className='ui-label required'>Your email</label>
                    <input className='ui-input' placeholder='johndoe@gmail.com' 
                        value={email}
                        onChange={e => setStore({ email: e.target.value })} />
                </div>
                <div className='ui-block'>
                    <label className='ui-label'>Phone number</label>
                    <PhoneInput
                        country={'us'}
                        className='ui-input'
                        value={phone}
                        onChange={(val, data, event, formattedValue, ...rest) => {
                            const country_code = data.dialCode
                            const phone_number = val.replace(country_code, '')
                            const phone = `+${country_code}-${phone_number}`
                            setStore({ 
                                phone, // ex: "+1-4081234567"
                                phone_country: data.name, // ex: 'Canada'
                                residing_country: data.name, // use phone country as default country for a future input
                            })
                        }}
                    />
                </div>
            </div>
            <div className='foot'>
                <div className='inner'>
                    <Button type='gray' onClick={()=>navigate(-1)}>BACK</Button>
                    <Button onClick={performSubmit} disabled={!canSubmit()}>NEXT</Button>
                </div>
            </div>
        </div>
    )
})




const Step3 = observer(() => {
    useScrollToTop()
    const navigate = useNavigate()

    // fetched from server
    const { 
        all_dev_roles = [], all_start_times = [], all_work_hours_per_week = [] 
    } = meStore.constants

    // mobx store
    const { 
        role, earliest_start_time, desired_hourly_rate, work_hours_per_week,
        saveToServer, setStore,
    } = applyStore

    // submit condition
    const canSubmit = () => {
        // all these fields must be filled
        const mandatory = { role, earliest_start_time, desired_hourly_rate, work_hours_per_week }
        for (const [key, val] of Object.entries(mandatory)){
            if (!val) return false 
        }
        return true
    }

    // click submit
    const performSubmit = async () => {
        saveToServer()
        Track.action('engineer_form_step3_done')
        navigate('/apply/step4')
    }

    return (
        <div className='step s3'>
            <h2>Your Work Availability</h2>
            <div className='content'>
                <div className='ui-block'>
                    <label className='ui-label required'>What role are you looking for?</label>
                    <Select 
                        options={all_dev_roles}
                        searchable={false}
                        clearable={false}
                        value={role}
                        onChange={str => setStore({ role: str })}
                    />
                </div>
                <div className='ui-block'>
                    <label className='ui-label required'>How soon can you accept an offer?</label>
                    <Select 
                        options={all_start_times}
                        searchable={false}
                        clearable={false}
                        value={earliest_start_time}
                        onChange={str => setStore({ earliest_start_time: str })}
                    />
                </div>
                <div className='ui-block'>
                    <label className='ui-label required'>What's your desired hourly rate?</label>
                    <input className='ui-input' 
                        type='number'
                        placeholder='0' 
                        min='0'
                        step='1'
                        style={{ width: 120 }}
                        value={desired_hourly_rate}
                        onChange={e => setStore({ desired_hourly_rate: e.target.value })}
                    />
                    <span style={{ marginLeft: 10, fontSize: 16 }}>USD / hour</span>
                </div>
                <div className='ui-block'>
                    <label className='ui-label required'>How many hours can you work per week?</label>
                    <Select 
                        options={all_work_hours_per_week}
                        searchable={false}
                        clearable={false}
                        value={work_hours_per_week}
                        onChange={str => setStore({ work_hours_per_week: str })}
                    />
                </div>
            </div>
            <div className='foot'>
                <div className='inner'>
                    <Button type='gray' onClick={()=>navigate(-1)}>BACK</Button>
                    <Button onClick={performSubmit} disabled={!canSubmit()}>NEXT</Button>
                </div>
            </div>
        </div>
    )
})






const Step4 = observer(() => {
    useScrollToTop()
    const navigate = useNavigate()

    // fetched from server
    const { 
        all_countries = [] 
    } = meStore.constants

    // mobx store
    const { 
        residing_country,
        saveToServer, setStore,
    } = applyStore


    // submit condition
    const canSubmit = () => {
        // all these fields must be filled
        const mandatory = { residing_country }
        for (const [key, val] of Object.entries(mandatory)){
            if (!val) return false 
        }
        return true
    }

    // click submit
    const performSubmit = async () => {
        saveToServer()
        Track.action('engineer_form_step4_done')
        navigate('/apply/step5')
    }

    return (
        <div className='step s4'>
            <h2>Where will you perform your work?</h2>
            <div className='content'>
                <div className='ui-block'>
                    <label className='ui-label required'>Country</label>
                    <Select 
                        options={all_countries}
                        searchable
                        clearable={false}
                        value={residing_country}
                        onChange={str => setStore({ residing_country: str })}
                    />
                </div>
            </div>
            <div className='foot'>
                <div className='inner'>
                    <Button type='gray' onClick={()=>navigate(-1)}>BACK</Button>
                    <Button onClick={performSubmit} disabled={!canSubmit()}>NEXT</Button>
                </div>
            </div>
        </div>
    )
})




const Step5 = observer(() => {
    useScrollToTop()
    const navigate = useNavigate()

    // fetched from server
    const { 
        all_countries = [], all_resume_allowed_extensions = [],
    } = meStore.constants

    // mobx store
    const { 
        resume_url, resume_metadata, linkedin_url, github_url, portfolio_url,
        saveToServer, setStore,
    } = applyStore

    // submit condition
    const canSubmit = () => {
        // all these fields must be filled
        const mandatory = { resume_url }
        for (const [key, val] of Object.entries(mandatory)){
            if (!val) return false 
        }
        return true
    }

    // click submit
    const performSubmit = async () => {
        setStore({ form_completed: true }).then(saveToServer)
        Track.action('engineer_form_step5_done')
        Track.action('engineer_form_completed')
        navigate('/apply/step6')
    }

    return (
        <div className='step s5'>
            <h2>Provide your resume</h2>
            <div className='content'>
                <div className='ui-block'>
                    <label className='ui-label required'>Upload resume</label>
                    <FileUpload
                        valid_extensions={all_resume_allowed_extensions}
                        default_file_metadata={resume_metadata}
                        onUploadSuccess={async ({url, metadata}) => {
                            await setStore({resume_url: url, resume_metadata: metadata})
                            saveToServer()
                        }}
                        onRemoveFile={async ()=>{
                            await setStore({resume_url: '', resume_metadata: null})
                            // saveToServer()
                        }}
                    />
                </div>
                <div className='ui-block'>
                    <label className='ui-label'>Linkedin URL</label>
                    <input className='ui-input' placeholder='' 
                        value={linkedin_url}
                        onChange={e => setStore({ linkedin_url: e.target.value })} />
                </div>
                <div className='ui-block'>
                    <label className='ui-label'>Github URL</label>
                    <input className='ui-input' placeholder='' 
                        value={github_url}
                        onChange={e => setStore({ github_url: e.target.value })} />
                </div>
            </div>
            <div className='foot'>
                <div className='inner'>
                    <Button type='gray' onClick={()=>navigate(-1)}>BACK</Button>
                    <Button onClick={performSubmit} disabled={!canSubmit()}>NEXT</Button>
                </div>
            </div>
        </div>
    )
})





const Step6 = () => {
    useScrollToTop()
    const navigate = useNavigate()
    const { full_name } = applyStore
    const first_name = _.capitalize(_.first(String(full_name).split(' ')))
    return (
        <div className='step s6'>
            <div className='title'>
                <div className='icon center-contained'/>
                <h1>Submitted</h1>
            </div>
            <div className='content'>
                <p>
                    {first_name ? `${first_name}, ` : ''}
                    Thanks for taking the time to apply at Peerku! 
                    We're so glad you're interested in us and we're here to help you 
                    find your perfect fit.
                </p>
                <br/><br/>
                <p>
                    We'll review your application carefully. Once you're matched, 
                    one of our recruiters will contact you directly with next steps.
                </p>
                <br/><br/>
                <p>Thank you!</p>
                <p><b>Peerku Recruiting Team</b></p>
            </div>
        </div>
    )
}




const Apply = () => {
    const { pathname } = useLocation()
    const [ page_did_mount, setMounted ] = useState(false) // component did mount
    const [ loading, setLoading ] = useState(false) // fetch saved form from server
    const { fetchSavedForm, current_step, total_steps, step_percent, setStep } = applyStore

    // on page mount
    useEffect(() => {
        // mark on body that we're in /apply page. 
        //  - this allows this component to override parent-level element's css, 
        //    like body's
        document.body.setAttribute('data-apply', 'true')
        setMounted(true)
        // on page mount, fetchpreviously saved form from server
        setLoading(true)
        fetchSavedForm().then(() => setLoading(false))
        // when page unmounts, remove the css mark
        return () => {
            document.body.removeAttribute('data-apply')
        }
    }, [])

    // make internal "step" variable in-sync with URL
    useEffect(() => {
        if (['/apply', '/apply/', '/apply/step1'].includes(pathname)) setStep(1)
        else if (pathname === '/apply/step2') setStep(2)
        else if (pathname === '/apply/step3') setStep(3)
        else if (pathname === '/apply/step4') setStep(4)
        else if (pathname === '/apply/step5') setStep(5)
        else if (pathname === '/apply/step6') setStep(6)
    }, [pathname])

    // Hack to prevent topbar flicker. Remove this you'll see topbar flickers.
    if (!page_did_mount) return null

    // in the process of fetching initial from
    if (loading) return <FullScreenLoader />

    // avoid showing "step 6/5" at the last screen (submitted successfully), lolz
    const show_steps = current_step <= total_steps

    return (
        <div className='apply'>
            <div className='top'>
                <Logo />
                {show_steps && <div className='step-count'>Step {current_step}/{total_steps}</div>}
            </div>
            <div className='progress'>
                <div className='inner' style={{ width: `${step_percent*100}%` }}></div>
            </div>
            <div className='mid'>
                {current_step === 1 && <Step1 />}
                {current_step === 2 && <Step2 />}
                {current_step === 3 && <Step3 />}
                {current_step === 4 && <Step4 />}
                {current_step === 5 && <Step5 />}
                {current_step === 6 && <Step6 />}
            </div>
        </div>
    )
}




export default observer(Apply);