import React, { useState, useEffect } from "react";
import { useMatch, useNavigate, matchPath, useLocation } from 'react-router-dom'
import { observer } from "mobx-react";
import cls from 'classnames';
import _ from "lodash";
import { viewport, meStore, hireStore } from 'stores'
import J from 'utils/standard';
import Track from 'utils/track'
import { useScrollToTop } from 'utils/hooks'
import FullScreenLoader from 'ui/full-screen-loader'
import PhoneInput from 'ui/phone-input'
import Link from 'ui/link'
import Select from 'ui/select'
import Button from 'ui/button'
import Highlight from 'ui/highlight'
import Logo from 'components/logo'
import "./style.css";


const About = () => {
    useScrollToTop()

    const highlight = {
        margin: 0,
        bottom: 0,
        height: '10px',
    }

    return (
        <div className='about'>
            <div className='main'>
                <div className='section slogan'>
                    <img src='/img/about1.jpg'></img>
                    <div className='content'>
                        <div className='title'>
                            We bring <Highlight blue {...highlight}>world-class</Highlight> talents, 
                            to <Highlight yellow {...highlight}>supercharge</Highlight> your team.
                        </div>
                        <div className='desc'>
                            Today, companies previously known for being HQ-centric are 
                            taking the leap to remote at-scale – Facebook recently 
                            predicted that <Link target='_blank' external to='https://www.nbcnews.com/tech/tech-news/mark-zuckerberg-half-facebook-may-work-remotely-2030-n1212081'>half of its workforce will be remote by 2030</Link>. 
                            Quora took a step even further and went fully-blown <Link target='_blank' external to='https://twitter.com/adamdangelo/status/1276210618786168833'>permanent remote</Link>.
                            <br/><br/>
                            Building remote teams is unique for all organizations. 
                            Our mission is to help businesses hiring the best 
                            talents worldwide, at a <b>much more affordable rates</b>, and 
                            empower your company to thrive.
                        </div>
                    </div>
                </div>
                <div className='section flex story'>
                    <img src='/img/about2.jpg'></img>
                    <div className='content'>
                        <div className='title'>
                            Our Story
                        </div>
                        <div className='desc'>
                            Prior to Peerku, our founders were tech leads 
                            at Facebook & Google and executives at unicorn startups. 
                            We know exactly what it takes to hire remote developers and build 
                            high performing engineering teams. Together, we wish to  
                            bring our expertise to help other companies onboarding great 
                            talents.
                        </div>
                    </div>
                </div>
                <div className='section flex explain'>
                    <div className='content'>
                        <div className='title'>
                            Empower You Throughout
                        </div>
                        <div className='desc'>
                            We're more than hiring - we forge strong connections with 
                            our clients by continuously supporting them throughout 
                            the entire development cycle. We're always here to 
                            help, whether it's for hiring advice, tooling support, payroll 
                            handling, or all the sticky issues of managing a 
                            remote team. Simply let us know and we'll do our best 
                            to assist you.
                        </div>
                    </div>
                    <img src='/img/about3.jpg'></img>
                </div>
                
                {/* <div className='section flex investor'>
                    <div className='title'>
                        Our investors
                    </div>
                    {['/img/investor1.png', 
                    '/img/investor2.png', 
                    '/img/investor3.png',
                    '/img/investor4.png', 
                    '/img/investor5.png',
                    ].map(url => (
                        <div key={url} className='pic-container'>
                            <div className='pic center-contained' style={J.bgUrl(url)}/>
                        </div>
                    ))}
                </div> */}

                <div className='section getstarted'>
                    <div className='title'>
                        Build your dream team
                    </div>
                    <div className='desc'>
                        Hire amazing talents, on demand.
                    </div>
                    <Button to='/how-it-works'>GET STARTED</Button>
                </div>
            </div>
        </div>
    )
}




export default observer(About);