import React, { useState, useEffect } from "react";
import { useMatch, useNavigate, matchPath, useLocation } from 'react-router-dom'
import { observer } from "mobx-react";
import cls from 'classnames';
import _ from "lodash";
import { viewport, meStore, hireStore } from 'stores'
import J from 'utils/standard';
import Track from 'utils/track'
import { useScrollToTop } from 'utils/hooks'
import FullScreenLoader from 'ui/full-screen-loader'
import PhoneInput from 'ui/phone-input'
import Select from 'ui/select'
import Button from 'ui/button'
import Logo from 'components/logo'
import "./style.css";


const Step1 = observer(() => {
    useScrollToTop()
    const navigate = useNavigate()
    const { is_mobile } = viewport

    // all available skills, worker types (fetched from server)
    const { all_skills = [], all_worker_types = [] } = meStore.constants
    
    // mobx store
    const { 
        selected_main_skills, selected_other_skills, 
        skills, worker_type, 
        saveToServer, setStore 
    } = hireStore
    
    // submit condition
    const canSubmit = () => {
        return _.size(skills) >= 1 && worker_type
    }

    // click submit
    const performSubmit = async () => {
        saveToServer()
        Track.action('consult_form_step1_done')
        navigate('/hire/step2')
    }

    return (
        <div className='step s1'>
            <h2>What skills does your engineer need?</h2>
            <h3>Select at least one skill</h3>
            <div className='content'>
                <div className='main-skills'>
                    <div className='inner ui-choose'>
                        {[
                        ['Not sure, need advice', '/img/skill-notsure.svg', null, "Not sure yet"],
                        ['React', '/img/skill-react.svg'],
                        ['Vue', '/img/skill-vue.svg'],
                        ['Angular', '/img/skill-angular.svg'],
                        ['Typescript', '/img/skill-ts.svg'],
                        ['Docker', '/img/skill-docker.svg'],
                        ['Android', '/img/skill-android.svg'],
                        ['Swift', '/img/skill-swift.svg'],
                        ['Python', '/img/skill-python.svg'],
                        ['Java', '/img/skill-java.svg'],
                        ['Spring', '/img/skill-spring.svg', true],
                        ['PHP', '/img/skill-php.svg'],
                        ['Go', '/img/skill-go.svg'],
                        ['Ruby', '/img/skill-ruby.svg'],
                        //['Asp.net', '/img/skill-aspnet.svg'],
                        //['C#', '/img/skill-csharp.svg'],
                        //['C++', '/img/skill-cpp.svg'],
                        ['Scala', '/img/skill-scala.svg', true],
                        //['Tensorflow', '/img/skill-tensorflow.svg'],
                        ['Data Engineer', '/img/skill-data.svg'],
                        ['Machine Learning', '/img/skill-ml.svg'],
                        ['AWS', '/img/skill-aws.svg'],
                        ].map(([skill, icon, hide_on_mobile, shorter_text_on_mobile]) => {
                            // we want a perfect 4x4 icons on mobile, so hide 2 of them
                            if (is_mobile && hide_on_mobile) return null
                            // skill name, certain skill displays shorter text on mobile
                            const displayed_text = (is_mobile && shorter_text_on_mobile) ? shorter_text_on_mobile : skill
                            // should we use smaller font
                            const smaller_font = displayed_text.length >= 10 && skill.indexOf(' ') >= 0
                            const is_chosen = selected_main_skills.includes(skill)
                            return <div key={skill} 
                                className='ui-option skill' 
                                data-selected={is_chosen}
                                onClick={() => {
                                    setStore({ 
                                        selected_main_skills: is_chosen 
                                            ? _.without(selected_main_skills, skill) 
                                            : [...selected_main_skills, skill]
                                    })
                                }}
                            >
                                <div className='icon center-contained' style={J.bgUrl(icon)}></div>
                                <div className='txt unselectable' data-smaller={smaller_font}>
                                    {displayed_text}
                                </div>
                            </div>
                        })}
                    </div>
                </div>
                <div className='other-skills'>
                    <Select 
                        placeholder='Enter other skills (500+ available)'
                        options={all_skills}
                        multi 
                        searchable
                        creatable
                        clearable={false}
                        value={selected_other_skills}
                        onChange={arr => setStore({ selected_other_skills: arr })}
                    />
                </div>
            </div>
            <div className='ui-block'>
                <label className='ui-label'>Type of hire</label>
                <div className='ui-choose'>
                    {all_worker_types.map(item => {
                        const is_chosen = worker_type === item
                        return <div key={item} 
                            className='ui-option' 
                            data-selected={is_chosen}
                            onClick={() => setStore({ worker_type: is_chosen ? null : item })}
                        >{item}</div>
                    })}
                </div>
            </div>
            <div className='foot'>
                <div className='inner'>
                    <Button onClick={performSubmit} disabled={!canSubmit()}>
                        NEXT
                    </Button>
                </div>
            </div>
        </div>
    )
})






const Step2 = observer(() => {
    useScrollToTop()
    const navigate = useNavigate()

    // fetched from server
    const { all_company_sizes = [] } = meStore.constants

    // mobx store
    const { 
        company_size, company_website, contact_email, contact_name, contact_phone,
        saveToServer, setStore,
    } = hireStore

    // submit condition
    const canSubmit = () => {
        // all these fields must be filled
        const mandatory = { company_size, contact_email, contact_name, contact_phone }
        for (const [key, val] of Object.entries(mandatory)){
            if (!val) return false 
        }
        // validate phone (rough validation, just check if there're digits after country code)
        if (!J.isCorrectPhone(contact_phone)) return false
        // validate email 
        if (!J.isValidEmail(contact_email)) return false
        return true
    }

    // click submit
    const performSubmit = async () => {
        saveToServer()
        Track.action('consult_form_step2_done')
        navigate('/hire/step3')
    }

    return (
        <div className='step s2'>
            <h2>Tell us a bit about your company</h2>
            <div className='content'>
                <div className='ui-block company-size'>
                    <label className='ui-label'>Company size (number of employees)</label>
                    <div className='ui-choose'>
                        {all_company_sizes.map(item => {
                            const is_chosen = company_size === item
                            return <div key={item} 
                                className='ui-option' 
                                data-selected={is_chosen}
                                onClick={() => setStore({ company_size: is_chosen ? null : item })}
                            >{item}</div>
                        })}
                    </div>
                </div>
                <div className='ui-block'>
                    <label className='ui-label'>Your name</label>
                    <input className='ui-input' placeholder='John Doe' 
                        value={contact_name}
                        onChange={e => setStore({ contact_name: e.target.value })} />
                </div>
                <div className='ui-block'>
                    <label className='ui-label'>Your work email</label>
                    <input className='ui-input' placeholder='john@yourcompany.com' 
                        value={contact_email}
                        onChange={e => setStore({ contact_email: e.target.value })} />
                </div>
                <div className='ui-block'>
                    <label className='ui-label'>Phone number</label>
                    <PhoneInput
                        country={'us'}
                        className='ui-input'
                        value={contact_phone}
                        onChange={(val, data, event, formattedValue) => {
                            const country_code = data.dialCode
                            const phone_number = val.replace(country_code, '')
                            const phone = `+${country_code}-${phone_number}`
                            setStore({ 
                                contact_phone: phone, // ex: "+1-408-123-4567"
                                contact_phone_beautified: formattedValue, // ex: "+1 (408) 123-4567"
                                contact_phone_country: data.name, // ex: 'Canada'
                                contact_phone_country_code: country_code, // ex: '1', '86'
                            })
                        }}
                        preferredCountries={['us']}
                    />
                </div>
                <div className='ui-block'>
                    <label className='ui-label'>Company website (optional)</label>
                    <input className='ui-input' placeholder='yourcompany.com' name='url'
                        value={company_website}
                        onChange={e => setStore({ company_website: e.target.value })} />
                </div>
            </div>
            <div className='foot'>
                <div className='inner'>
                    <Button onClick={performSubmit} disabled={!canSubmit()}>
                        NEXT
                    </Button>
                </div>
            </div>
        </div>
    )
})




const Step3 = observer(() => {
    useScrollToTop()
    const [loading, setLoading] = useState(false)

    const { setStore, saveToServer } = hireStore

    // load Calendly widget
    const loadCalendlyScript = () => {
        setLoading(true)
        const asset = `https://assets.calendly.com/assets/external/widget.js`
        const script = document.createElement("script");
        script.src = asset;
        script.async = true;
        document.body.appendChild(script);
    }

    // catch user interaction with Calendly
    const onCalendlyEvent = async e => {
        const event = (e.data && _.isString(e.data.event)) ? e.data.event : ''
        if (event.indexOf('calendly') !== 0) return
        // calendly iframe is loaded
        if (event === "calendly.event_type_viewed"){
            setLoading(false)
            Track.action('consult_form_calendly_loaded')
        }
        // user selected a date & time, clicked "confirmed" button, 
        // and arrived at "Enter Details" page
        if (event === 'calendly.date_and_time_selected'){
            // scroll to top, because user might've previously scrolled down,
            // which makes current page load in the middle, and user gets confused
            window.scrollTo(0, 0)
            Track.action('consult_form_calendly_chosen_date')
        } 
        // user completely scheduling!
        else if (event === "calendly.event_scheduled"){
            // scroll to top for same reason as above
            window.scrollTo(0, 0)
            // tell server this form is complete
            await setStore({ form_completed: true })
            saveToServer()
            Track.action('consult_form_calendly_scheduled')
        }
    }

    // on mount
    useEffect(() => {
        // 1. load calendly widget
        loadCalendlyScript()
        // 2. bind listener to catch Calendly events 
        window.addEventListener('message', onCalendlyEvent)
        return () => {
            window.removeEventListener('message', onCalendlyEvent)
        }
    }, [])

    const { contact_name, contact_email, contact_phone } = hireStore
    const queries = {
        primary_color: '06c290',
        name: contact_name,
        email: contact_email,
    }
    const query = _.map(queries, (val, key) => val ? `${key}=${val}` : '')
        .filter(Boolean)
        .join('&')
    const url = "https://calendly.com/peerku/consultation?" + query

    return (
        <div className='s3'>
        
            {loading ? <FullScreenLoader /> : null}

            {/* the container below must EXIST before script starts to load, because
            after script loads, it'll insert iframe inside that. previously i render 
            the below only fter loading is done, that is a mistake because after script 
            loads it cannot find this container... */}
            <div className="calendly-wrap calendly-inline-widget" 
                data-url={url}  
                style={{ minWidth: 320, height: 730 }}
            />
        </div>
    )
})




const Hire = () => {
    const { pathname } = useLocation()
    const [ page_did_mount, setMounted ] = useState(false) // component did mount
    const [ loading, setLoading ] = useState(false) // fetch saved form from server
    const { fetchSavedForm, current_step, total_steps, step_percent, setStep } = hireStore

    // on page mount
    useEffect(() => {
        // mark on body that we're in /hire page. 
        //  - this allows this component to override parent-level element's css, 
        //    like body's
        document.body.setAttribute('data-hire', 'true')
        setMounted(true)
        // on page mount, fetchpreviously saved form from server
        setLoading(true)
        fetchSavedForm().then(() => setLoading(false))
        // when page unmounts, remove the css mark
        return () => {
            document.body.removeAttribute('data-hire')
        }
    }, [])

    // make internal "step" variable in-sync with URL
    useEffect(() => {
        if (['/hire', '/hire/', '/hire/step1'].includes(pathname)) setStep(1)
        else if (pathname === '/hire/step2') setStep(2)
        else if (pathname === '/hire/step3') setStep(3)
    }, [pathname])

    // Hack to prevent topbar flicker. Remove this you'll see topbar flickers.
    if (!page_did_mount) return null;

    // in the process of fetching initial from
    if (loading) return <FullScreenLoader />

    return (
        <div className='hire'>
            {/* 
                - all steps have a topbar, and follow the same HTML structure
                - however, last step is an exception: it is a standalone page
            */}
            {current_step === 3 
                ? <Step3 /> 
                : <>
                    <div className='top'>
                        <Logo />
                        <div className='step-count'>Step {current_step}/{total_steps}</div>
                    </div>
                    <div className='progress'>
                        <div className='inner' style={{ width: `${step_percent*100}%` }}></div>
                    </div>
                    <div className='mid'>
                        {current_step === 1 && <Step1 />}
                        {current_step === 2 && <Step2 />}
                    </div>
                </>
            }
        </div>
    )
}




export default observer(Hire);