
import React, { useState, useEffect } from "react";
import { useMatch, useNavigate, matchPath, useLocation } from 'react-router-dom'
import cls from 'classnames';
import _ from "lodash";
import "./style.css";

// Create a wrapper so we can add tracking onto it
const FullScreenLoader = () => {
    return (
        <div className='full-screen-loader'>
            <div className='spinner'></div>
        </div>
    )
}


export default FullScreenLoader