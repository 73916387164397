import React, { useState } from "react";
import _ from "lodash";
import cls from "classnames";
import Logo from 'components/logo'
import Link from 'ui/link'
import "./style.css";



const PlainArticle = ({ title, subtitle, className, children }) => {
    return (
        <div className={cls('plain-article', className)}>
            <div className='banner'>
                <h1 className='title'>{title}</h1>
                <div className='subtitle'>{subtitle}</div>
            </div>
            <div className='main'>
                <div className='inner'>
                    {children}
                </div>
            </div>
        </div>
    )
}




export default PlainArticle